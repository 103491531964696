import { Radio, RadioGroup, Stack } from '@chakra-ui/react'

interface BatterPovToggleProps {
  batter: boolean;
  setBatter: (value: boolean) => void;
}

export default function BatterPovToggle(props: BatterPovToggleProps) {

  function setValue(value: string) {
    const isBatter = value === 'batter';
    props.setBatter(isBatter);
  }

  const value = props.batter ? 'batter' : 'pitcher';

  return (
    <RadioGroup onChange={setValue} value={value}>
      <Stack direction='row'>
        <Radio value='batter'>Batter</Radio>
        <Radio value='pitcher'>Pitcher</Radio>
      </Stack>
    </RadioGroup>
  );
}
