import { Link, useColorModeValue } from '@chakra-ui/react';
import { getAuth, signOut } from 'firebase/auth';
import firebaseApp from '../../firebase';

const auth = getAuth(firebaseApp);

const logout = () => {
  signOut(auth);
};

export default function LogOut() {

  return (
    <Link
      onClick={logout}
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      >
      Log Out
    </Link>
  );
}
