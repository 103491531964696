const pitchMixAlpha = 0.01;
const commonPitchAlpha = 0.005;

export function calcPitchMix(pitchTypes: string[], alpha: number = pitchMixAlpha): Map<string, number> {
  const total = pitchTypes.length;
  let counts = new Map<string, number>();
  for (const pitch of pitchTypes) {
    const count = (counts.get(pitch) || 0) + 1;
    counts.set(pitch, count);
  }
  for (const pitch of Array.from(counts.keys())) {
    const count = (counts.get(pitch) || 0) + 1;
    if (count / total < alpha) {
      counts.delete(pitch);
    }
  }
  return counts;
}

export function determineCommonPitches(pitchTypes: string[], alpha: number = commonPitchAlpha): string[] {
  return Array.from(calcPitchMix(pitchTypes, alpha).keys());
}

// TODO: Stop using this eventually.
export function determineBatterStance(stands: string[]) {
  if (stands.length === 0) {
    return null;
  }
  let right = 0;
  let left = 0;
  for (const stand of stands) {
    if (stand === 'L') {
      left++;
    } else if (stand === 'R') {
      right++;
    }
  }
  const stance = right > left ? 'R' : 'L';
  return stance;
}

// TODO: Stop using this eventually.
export function determinePitcherThrows(p_throws: string[]): 'L' | 'R' | null {
  if (p_throws.length === 0) {
    return null;
  }
  let right = 0;
  let left = 0;
  for (const p_throw of p_throws) {
    if (p_throw === 'L') {
      left++;
    } else if (p_throw === 'R') {
      right++;
    }
  }
  const throws = right > left ? 'R' : 'L';
  return throws;
}
