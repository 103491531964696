import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import { dontDrawAxisOptions, zeroMargins } from '../../../shared/constants/plotly';
import { StatcastRawBatterData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import { filterBatterDataByExactCount, filterBatterDidSwing } from '../../../shared/utils/filterUtils';
import { blackStrikeZone } from '../../../shared/utils/plotUtils';
import { groupByContact, getPlatexPov } from '../../../shared/utils/statcastUtils';

interface BatterSwingScatterProps {
  data: StatcastRawBatterData;
  batterPov: boolean;
  countFilter?: Count;
}

export default function BatterSwingScatter(props: BatterSwingScatterProps) {

  const filteredSwing = filterBatterDidSwing(props.data);
  const filtered = filterBatterDataByExactCount(filteredSwing, props.countFilter);
  const [contact, miss] = groupByContact(filtered);

  const contactTrace = {
    x: getPlatexPov(contact, props.batterPov),
    y: contact.map(x => x.plate_z),
    type: 'scattergl',
    mode: 'markers',
    opacity: 0.5,
    marker: { color: 'blue' },
    name: 'Contact',
  } as Plotly.Data;

  const missTrace = {
    x: getPlatexPov(miss, props.batterPov),
    y: miss.map(x => x.plate_z),
    type: 'scattergl',
    mode: 'markers',
    opacity: 0.5,
    marker: { color: 'red' },
    name: 'Miss',
  } as Plotly.Data;

  const yaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    zeroline: true,
    zerolinecolor: '#af683c',
    zerolinewidth: 2,
    range: [0, 5],
    constrain: 'domain',
    scaleanchor: 'x',
    scaleratio: 1,
  } as Partial<Plotly.LayoutAxis>;

  const xaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    range: [-2, 2],
    constrain: 'domain',
  } as Partial<Plotly.LayoutAxis>;

  const legend = {
    x: 1,
    xanchor: 'right',
    y: 1,
    bordercolor: 'lightgrey',
    borderwidth: 1,
  } as Partial<Plotly.Legend>;

  const shapes = [blackStrikeZone];

  const margin = zeroMargins;

  return (
    <Plot
      data={[contactTrace, missTrace]}
      layout={ {
        yaxis,
        xaxis,
        shapes,
        autosize: true,
        margin,
        legend
      } }
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      config={{ staticPlot: true }}
    />
  );

}
