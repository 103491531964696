export const countingStats = {
  'ab': 'At bats',
  'rbi': 'RBI',
  'h': 'Hits',
  'h1b': 'Singles',
  'h2b': 'Doubles',
  'h3b': 'Triples',
  'hr': 'Home runs',
  'tb': 'Total bases',
  'so': 'Strike outs',
  'runs_scored': 'Runs scored',
};

export const calculatedStats = {
  'runs_added': 'Run expectancy added',
};

export const contribStats = {
  ...calculatedStats, ...countingStats
};

export const statsCwDaily = {
  'b_ab': 'At bats',
  'b_rbi': 'RBI',
  'b_h': 'Hits',
  // 'b_1b': 'Singles', # need to calculate this
  'b_2b': 'Doubles',
  'b_3b': 'Triples',
  'b_hr': 'Home runs',
  'b_tb': 'Total bases',
  'b_so': 'Strike outs',
  'b_r': 'Runs scored',
  'b_sb': 'Stolen bases',
};

export const franchiseNames = {
  'ANA': ['Los Angeles', 'Angels'],
  'ARI': ['Arizona', 'Diamondbacks'],
  'ATL': ['Atlanta', 'Braves'],
  'BAL': ['Baltimore', 'Orioles'],
  'BOS': ['Boston', 'Red Sox'],
  'CHC': ['Chicago', 'Cubs'],
  'CHW': ['Chicago', 'White Sox'],
  'CIN': ['Cincinnati', 'Reds'],
  'CLE': ['Cleveland', 'Indians'],
  'COL': ['Colorado', 'Rockies'],
  'DET': ['Detroit', 'Tigers'],
  'FLA': ['Miami', 'Marlins'],
  'HOU': ['Houston', 'Astros'],
  'KCR': ['Kansas City', 'Royals'],
  'LAD': ['Los Angeles', 'Dodgers'],
  'MIL': ['Milwaukee', 'Brewers'],
  'MIN': ['Minnesota', 'Twins'],
  'NYM': ['New York', 'Mets'],
  'NYY': ['New York', 'Yankees'],
  'OAK': ['Oakland', 'Athletics'],
  'PHI': ['Philadelphia', 'Phillies'],
  'PIT': ['Pittsburgh', 'Pirates'],
  'SDP': ['San Diego', 'Padres'],
  'SEA': ['Seattle', 'Mariners'],
  'SFG': ['San Francisco', 'Giants'],
  'STL': ['St. Louis', 'Cardinals'],
  'TBD': ['Tampa Bay', 'Rays'],
  'TEX': ['Texas', 'Rangers'],
  'TOR': ['Toronto', 'Blue Jays'],
  'WSN': ['Washington', 'Nationals']
};

// FRANCHISE_NAMES_SORTED = sorted(
//   [(v[1], k) for k, v in FRANCHISE_NAMES.items()], key=lambda x: x[0])

export const franchiseFirstRetroSeason = {
  'ANA': 1961,
  'ARI': 1998,
  'ATL': 1918,
  'BAL': 1918,
  'BOS': 1918,
  'CHC': 1918,
  'CHW': 1918,
  'CIN': 1918,
  'CLE': 1918,
  'COL': 1993,
  'DET': 1918,
  'FLA': 1993,
  'HOU': 1962,
  'KCR': 1969,
  'LAD': 1918,
  'MIL': 1969,
  'MIN': 1918,
  'NYM': 1962,
  'NYY': 1918,
  'OAK': 1918,
  'PHI': 1918,
  'PIT': 1918,
  'SDP': 1969,
  'SEA': 1977,
  'SFG': 1918,
  'STL': 1918,
  'TBD': 1998,
  'TEX': 1961,
  'TOR': 1977,
  'WSN': 1969
};

export const mlbTeams: { [key: number]: [string, string, string, string] } = {
  133: ['OAK', 'Athletics', 'Oakland', 'Oakland Athletics'],
  134: ['PIT', 'Pirates', 'Pittsburgh', 'Pittsburgh Pirates'],
  135: ['SD', 'Padres', 'San Diego', 'San Diego Padres'],
  136: ['SEA', 'Mariners', 'Seattle', 'Seattle Mariners'],
  137: ['SF', 'Giants', 'San Francisco', 'San Francisco Giants'],
  138: ['STL', 'Cardinals', 'St. Louis', 'St. Louis Cardinals'],
  139: ['TB', 'Rays', 'St. Petersburg', 'Tampa Bay Rays'],
  140: ['TEX', 'Rangers', 'Arlington', 'Texas Rangers'],
  141: ['TOR', 'Blue Jays', 'Toronto', 'Toronto Blue Jays'],
  142: ['MIN', 'Twins', 'Minneapolis', 'Minnesota Twins'],
  143: ['PHI', 'Phillies', 'Philadelphia', 'Philadelphia Phillies'],
  144: ['ATL', 'Braves', 'Atlanta', 'Atlanta Braves'],
  145: ['CWS', 'White Sox', 'Chicago', 'Chicago White Sox'],
  146: ['MIA', 'Marlins', 'Miami', 'Miami Marlins'],
  147: ['NYY', 'Yankees', 'Bronx', 'New York Yankees'],
  158: ['MIL', 'Brewers', 'Milwaukee', 'Milwaukee Brewers'],
  108: ['LAA', 'Angels', 'Anaheim', 'Los Angeles Angels'],
  109: ['ARI', 'D-backs', 'Phoenix', 'Arizona Diamondbacks'],
  110: ['BAL', 'Orioles', 'Baltimore', 'Baltimore Orioles'],
  111: ['BOS', 'Red Sox', 'Boston', 'Boston Red Sox'],
  112: ['CHC', 'Cubs', 'Chicago', 'Chicago Cubs'],
  113: ['CIN', 'Reds', 'Cincinnati', 'Cincinnati Reds'],
  114: ['CLE', 'Guardians', 'Cleveland', 'Cleveland Guardians'],
  115: ['COL', 'Rockies', 'Denver', 'Colorado Rockies'],
  116: ['DET', 'Tigers', 'Detroit', 'Detroit Tigers'],
  117: ['HOU', 'Astros', 'Houston', 'Houston Astros'],
  118: ['KC', 'Royals', 'Kansas City', 'Kansas City Royals'],
  119: ['LAD', 'Dodgers', 'Los Angeles', 'Los Angeles Dodgers'],
  120: ['WSH', 'Nationals', 'Washington', 'Washington Nationals'],
  121: ['NYM', 'Mets', 'Flushing', 'New York Mets']
};

export const getTeamAbbr = (teamId: number) => mlbTeams[teamId]?.[0];

export const getTeamName = (teamId: number) => mlbTeams[teamId]?.[1];

export const getTeamLocation = (teamId: number) => mlbTeams[teamId]?.[2];

export const getTeamFullName = (teamId: number) => mlbTeams[teamId]?.[3];
