export function isObject(item: any) {
  return typeof item === 'object' && !Array.isArray(item) && item !== null;
}

export function parseInt2(s: string): number | null {
  const re = /^\d+$/;
  if (s.match(re) == null) {
    return null;
  }
  return parseInt(s);
}

export function getInitialWindowSize() {
  try {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  } catch(e) {
    return {
      width: 1024,
      height: 768
    };
  }
}
