import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { getTeamGameScores } from '../api/backendApi';
import { GameScore } from '../shared/interfaces/apiInterfaces';
import TeamDropdown from '../components/selectors/TeamDropdown';
import GameScoreReviewLink from '../components/review/GameScoreReviewLink';

export default function GameReviewHome() {

  const [teamId, setTeamId] = useState<number | null>(null);

  const [gameScores, setGameScores] = useState<GameScore[]>([]);

  useEffect(() => {
    if (teamId != null) {
      getTeamGameScores(teamId).then(setGameScores);
    } else {
      setGameScores([]);
    }
  }, [teamId]);

  return (
    <Box>
      <Box width='225px'>
        <TeamDropdown value={teamId} onChange={setTeamId} />
        {gameScores.map((game) => <GameScoreReviewLink key={game.gamepk} game={game} />)}
      </Box>
    </Box>
  );
}
