import { useEffect, useReducer, useState } from 'react';
import { Box } from '@chakra-ui/react';
import io, { Socket } from 'socket.io-client';
import { PlayerStatsStore } from '../../shared/classes/PlayerStatsStore';
import { GumboUpdate, InitialGameData } from '../../shared/interfaces/apiInterfaces';
import { formatTimeOnly } from '../../shared/utils/formatUtils';
import { initialLiveGameData, liveGameDataReducer } from '../../reducers/liveDataReducer';
import { getPreparedStats } from '../../api/liveApi';
import LiveGamePreferences from './LiveGamePreferences';

const backend = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

enum ConnectionStatus {
  CONNECTING,
  CONNECTED,
  DISCONNECTED
}

interface LiveGameDataFetcherProps {
  gamePk: number;
  gameNotLive: () => void;
}

export default function LiveGameDataFetcher(props: LiveGameDataFetcherProps) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [status, setStatus] = useState<ConnectionStatus>(ConnectionStatus.CONNECTING);

  const [data, dispatch] = useReducer(liveGameDataReducer, initialLiveGameData());

  const [preparedData, setPreparedData] = useState<PlayerStatsStore | null>(null);

  useEffect(() => {
    if (preparedData == null) {
      getPreparedStats(props.gamePk).then((data) => {
        setPreparedData(new PlayerStatsStore(data));
      });
    }
  });

  useEffect(() => {
    const newSocket = io(`${backend}`);
    setSocket(newSocket);

    return () => { newSocket.disconnect(); }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        socket.emit('join', { room: props.gamePk });
      });

      socket.on('disconnect', () => {
        // TODO: need to make sure we didn't miss any updates
        // TODO: need to display a message to the user after being disconnected for a certain amount of time
        setStatus(ConnectionStatus.DISCONNECTED);
      });

      socket.on('join_response', (data) => {
        if (data.error) {
          props.gameNotLive();
          socket.disconnect();
        } else {
          setStatus(ConnectionStatus.CONNECTED);
        }
      });

      socket.on('initial_game_state', (data: InitialGameData) => {
        printInitialGameData(data);
        dispatch({ type: 'initial_data', data: data });
      });

      socket.on('game_update', (updates: GumboUpdate[]) => {
        printUpdates(updates);
        dispatch({ type: 'game_update', updates: updates });
      });

      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('join_response');
        socket.off('initial_game_state');
        socket.off('game_update');
      };
    }
  }, [socket]);

  if (data.gumbo == null) {
    return (
      <Box>
        Loading game data...
      </Box>
    );
  }

  if (preparedData == null) {
    return (
      <Box>
        Loading player data...
      </Box>
    );
  }

  // if (data.gumbo == null || preparedData == null) {
  //   return (
  //     <Box>
  //       Loading...
  //     </Box>
  //   );
  // }

  return (
    <LiveGamePreferences
      gumbo={data.gumbo}
      updates={data.updates}
      preparedData={preparedData}
    />
  );
}

function printInitialGameData(data: InitialGameData) {
  const time = formatTimeOnly(new Date());
  // should just print the gumbo timestamp (if it exists)
  const timestamp = data?.gumbo?.metaData?.timeStamp;
  const gumboInfo = `gumbo: ${timestamp}`;
  const msg = `${time} - gumbo: ${timestamp} - ${updatesAsSummary(data.updates)}`;
  console.log(msg);
}

function printUpdates(updates: GumboUpdate[]) {
  const time = formatTimeOnly(new Date());
  const msg = `${time} - ${updatesAsSummary(updates)}`;
  console.log(msg);
}

function updatesAsSummary(updates: GumboUpdate[]): string {
  return updates.map(updateAsSummary).join(', ');
}

function updateAsSummary(update: GumboUpdate): string {
  return `[${update.type} - ${update.index} - ${update.timestamp}]`;
}
