import React from 'react';
import { Checkbox } from '@chakra-ui/react';

interface BooleanCheckboxProps {
  value: boolean;
  setValue: (value: boolean) => void;
  text?: string;
}

export default function BooleanCheckbox(props: BooleanCheckboxProps) {

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.setValue(event.target.checked);
  }

  return (
    <Checkbox isChecked={props.value} onChange={onChange}>
      {props.text}
    </Checkbox>
  );
}
