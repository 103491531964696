import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { teamsAbbr } from '../../shared/constants/statcast';
import { TeamPlayers } from '../../shared/interfaces/apiInterfaces';
import { getTeamBatters, getTeamPitchers } from '../../api/backendApi';
import TeamAbbrDropdown from './TeamAbbrDropdown';
import PlayerDropdown from './PlayerDropdown';
import SidebarDropdown from './SidebarDropdown';

interface TeamPlayerSelectorProps {
  batters: boolean;
  season: number;
  player: number;
  onPlayerChange: (value: number) => void;
  team: string;
  onTeamChange: (value: string) => void;
}

export default function TeamPlayerSelector(props: TeamPlayerSelectorProps) {

  const [teamPlayers, setTeamPlayers] = useState<TeamPlayers | null>(null);

  useEffect(() => {
    if (props.batters) {
      getTeamBatters(props.season).then(data => {
        setTeamPlayers(data);
      });
    } else {
      getTeamPitchers(props.season).then(data => {
        setTeamPlayers(data);
      });
    }
  }, [props.season, props.batters]);

  let players: [string, number][] = [];
  if (teamPlayers != null) {
    players = teamPlayers[props.team];
  }

  return (
    <Box>
      <SidebarDropdown>
        <TeamAbbrDropdown teams={teamsAbbr} value={props.team} onChange={props.onTeamChange} />
      </SidebarDropdown>
      <SidebarDropdown>
        <PlayerDropdown players={players} value={props.player} onChange={props.onPlayerChange} />
      </SidebarDropdown>
    </Box>
  );
}
