import { useState } from 'react';
import { Box, Checkbox, HStack, VStack } from '@chakra-ui/react';
import { StatcastRawPitcherData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import ReleasePointScatter from '../plotly/ReleasePointScatter';
import ReleasePointScatterStatic from '../plotly/ReleasePointScatterStatic';
import React from 'react';

interface ReleasePointContainerProps {
  data: StatcastRawPitcherData;
  batterPov: boolean;
  countFilter?: Count;
}

function ReleasePointContainer(props: ReleasePointContainerProps) {

  const [displayStatic, setDisplayStatic] = useState(false);

  return (
    <VStack
      justify='space-between'
      border='1px solid lightgrey'
      borderRadius='md'
      width='404px'
    >
      <HStack>
        <Box>Pitch Release Point</Box>
        <Checkbox
          isChecked={displayStatic}
          onChange={(e) => setDisplayStatic(e.target.checked)}
        >
          Static Axes
        </Checkbox>
      </HStack>
      <Box height='350px' width='350px'>
        {displayStatic ?
        <ReleasePointScatterStatic
        batterPov={props.batterPov}
        data={props.data}
        countFilter={props.countFilter}
      />
        :
        <ReleasePointScatter
          batterPov={props.batterPov}
          data={props.data}
          countFilter={props.countFilter}
        />
        }
      </Box>
    </VStack>
  );
}

export default React.memo(ReleasePointContainer);
