import { Box } from '@chakra-ui/react';
import ReactJson from 'react-json-view'
import { Play } from '../../shared/interfaces/gumboInterfaces';

interface GumboPlayJsonViewerProps {
  play: Play;
}

export default function GumboPlayJsonViewer(props: GumboPlayJsonViewerProps) {

  return (
    <Box w='500px' h='815px' textAlign='left' overflow='scroll'>
      <ReactJson
        src={props.play}
        collapsed={1}
        displayDataTypes={false}
      />
    </Box>
  );
}
