import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, IconButton, Input, useNumberInput } from '@chakra-ui/react';

interface NumberInputStep1Props {
  value: number;
  setValue: (value: number) => void;
  min: number;
  max: number;
  name: string;
}

export default function NumberInputStep1(props: NumberInputStep1Props) {
  const onChange = (_: string, valueAsNumber: number) => props.setValue(valueAsNumber);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 0,
      min: props.min,
      max: props.max,
      value: props.value,
      onChange: onChange
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <HStack maxW='320px'>
      <Box>{props.name}</Box>
      <IconButton
        {...dec}
        icon={<ChevronLeftIcon />}
        aria-label={`Back one ${props.name.toLowerCase()}`}
      />
      <Input {...input} />
      <IconButton
        {...inc}
        icon={<ChevronRightIcon />}
        aria-label={`Forward one ${props.name.toLowerCase()}`}
      />
    </HStack>
  );
}
