import fp from 'lodash/fp';
import { StatsApiLinescore, StatsApiLinescoreResponse } from '../interfaces/apiInterfaces';

export function createFakeLinescore(
  pitcherId?: number,
  batterId?: number,
  balls?: number,
  strikes?: number,
  outs?: number
): StatsApiLinescore {
  let linescore = { ...fakeLinescore };
  if (pitcherId != null) {
    linescore.pitcher_id = pitcherId;
  }
  if (batterId != null) {
    linescore.batter_id = batterId;
  }
  if (balls != null) {
    linescore.balls = balls;
  }
  if (strikes != null) {
    linescore.strikes = strikes;
  }
  if (outs != null) {
    linescore.outs = outs;
  }
  return linescore;
}

export function asFakeLinescoreResponse(linescore: StatsApiLinescore): StatsApiLinescoreResponse {
  return {
    game_state: 'Live',
    linescore: linescore
  };
}

export const createFakeLinescoreResponse = fp.compose(asFakeLinescoreResponse, createFakeLinescore);

export const fakeLinescore: StatsApiLinescore = {
  "away_errors": 1,
  "away_hits": 4,
  "away_lob": 3,
  "away_runs": 0,
  "away_team_id": 158,
  "away_team_name": "Milwaukee Brewers",
  "balls": 0,
  "bases": "000",
  "batter_id": 642133,
  "batter_name": "Rowdy Tellez",
  "catcher_id": 608348,
  "catcher_name": "Carson Kelly",
  "center_id": 677950,
  "center_name": "Alek Thomas",
  "first_id": 572233,
  "first_name": "Christian Walker",
  "game_pk": 663290,
  "home_errors": 0,
  "home_hits": 10,
  "home_lob": 8,
  "home_runs": 5,
  "home_team_id": 109,
  "home_team_name": "Arizona Diamondbacks",
  "in_hole_id": 543939,
  "in_hole_name": "Kolten Wong",
  "inning": 9,
  "is_top_inning": true,
  "left_id": 682998,
  "left_name": "Corbin Carroll",
  "on_deck_id": 592669,
  "on_deck_name": "Hunter Renfroe",
  "outs": 1,
  "pitcher_id": 592761,
  "pitcher_name": "Caleb Smith",
  "received_at": "Fri, 02 Sep 2022 04:10:18 GMT",
  "response_id": 2212,
  "right_id": 662139,
  "right_name": "Daulton Varsho",
  "runner_1b_id": null,
  "runner_1b_name": null,
  "runner_2b_id": 663290,
  "runner_2b_name": "Christian Walker", // This was copied and pasted from first_name
  "runner_3b_id": null,
  "runner_3b_name": null,
  "second_id": 606466,
  "second_name": "Ketel Marte",
  "shortstop_id": 672695,
  "shortstop_name": "Geraldo Perdomo",
  "strikes": 2,
  "third_id": 668942,
  "third_name": "Josh Rojas"
}
