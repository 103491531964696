import Plot from 'react-plotly.js';

function LaunchAngleBoxplot(props: LaunchAngleBoxplotProps) {

  let y: number[] = [];

  for (let i = 0; i < props.type.length; i++) {
    if (props.type[i] === 'X') {
      y.push(props.launch_angle[i]);
    }
  }

  return (
    <Plot
      data={[
        {
          y,
          type: 'box',
        },
      ]}
      layout={{
        title: 'Launch Angle',
      }}
      config={{ staticPlot: true }}
    />
  );
}

interface LaunchAngleBoxplotProps {
  launch_angle: number[];
  type: string[];
}

export default LaunchAngleBoxplot;
