import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './App.css';
import PitcherProfile from './pages/PitcherProfile';
import BatterProfile from './pages/BatterProfile';
import HomePage from './pages/Home';
import NavBar from './components/nav/Navbar';
import LiveSchedule from './pages/LiveSchedule';
import LiveGame from './pages/LiveGame';
import LiveGameOld from './pages/LiveGameOld';
import Matchup from './pages/Matchup';
import Playground from './pages/Playground';
import GameReview from './pages/GameReview';
import GameReviewHome from './pages/GameReviewHome';

const links = [
  { href: '/', children: 'Home' },
  { href: '/pitcherprofile', children: 'Pitcher Profile' },
  { href: '/batterprofile', children: 'Batter Profile' },
  { href: '/matchup', children: 'Matchup' },
  { href: '/live/schedule', children: 'Live' },
  { href: '/gamereview', children: 'Game Review' },
  { href: '/playground', children: 'Playground' },
];

function App() {

  return (
    <div className='App'>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <NavBar links={links} />
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/pitcherprofile' element={<PitcherProfile />} />
            <Route path='/batterprofile' element={<BatterProfile />} />
            <Route path='/live/schedule' element={<LiveSchedule />} />
            <Route path='/matchup' element={<Matchup />} />
            <Route path='/live/game/:gamePk' element={<LiveGame />} />
            <Route path='/liveold/game/:gamePk' element={<LiveGameOld />} />
            <Route path='/gamereview/game/:gamePk' element={<GameReview />} />
            <Route path='/gamereview' element={<GameReviewHome />} />
            <Route path='/playground' element={<Playground />} />
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
