import { Box, HStack, Spacer } from '@chakra-ui/react';
import { pitchToColor, pitchToName } from '../../../shared/constants/statcast';
import { StatcastPitchAverages } from '../../../shared/interfaces/apiInterfaces';
import { orderItemsWithPitches } from '../../../shared/utils/filterUtils';

interface FatiguePitchMeanLegendProps {
  averages: StatcastPitchAverages[];
  isVelo: boolean;
}

export default function FatiguePitchMeanLegend(props: FatiguePitchMeanLegendProps) {

  const ordered = orderItemsWithPitches(props.averages, (x: StatcastPitchAverages) => x.pitch_type);

  return (
    <Box
      border='1px solid'
      borderColor='gray.300'
      display='inline-block'
    >
      {ordered.map((pitch) => {
        return (
          <HStack key={pitch.pitch_type} paddingLeft={2} paddingRight={2}>
            <HStack>
              <Box
                w='6px'
                h='6px'
                borderRadius='50%'
                bg={pitchToColor(pitch.pitch_type)}
              />
              <Box fontSize='12px'>
                {pitchToName(pitch.pitch_type)}
              </Box>
            </HStack>
            <Spacer />
            <Box fontSize='12px'>
              {props.isVelo ?
              `Mean velo: ${pitch.avg_release_speed.toFixed(1)} mph`
              : `Mean spin rate: ${pitch.avg_release_spin_rate.toFixed(0)} rpm`}
            </Box>
          </HStack>
        );
      })}
    </Box>
  );
}
