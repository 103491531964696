import { determineCommonPitches } from '../../../shared/utils/calcUtils';
import PitchLegend from './PitchLegend';

interface PitchLegendProps {
  pitch_type: string[];
}

export default function FilteredPitchLegend(props: PitchLegendProps) {

  const pitches = determineCommonPitches(props.pitch_type);

  return (
    <PitchLegend pitches={pitches} />
  );
}
