import { Box, HStack } from '@chakra-ui/react'
import { StatcastRawBatterData, StatcastRawPitcherData } from '../../shared/interfaces/apiInterfaces';
import { Gumbo } from '../../shared/interfaces/gumboInterfaces';
import { determineBatterStance, determineCommonPitches, determinePitcherThrows } from '../../shared/utils/calcUtils';
import { filterVsBatterHandedness, filterVsPitcherHandedness } from '../../shared/utils/filterUtils';
import { getGumboCount } from '../../shared/utils/gumbo/gumboUtils';
import BatterSwingScatterContainer from '../charts/containers/BatterSwingScatterContainer';
import SprayChartContainer from '../charts/containers/SprayChartContainer';
import PitchMovementContainer from '../charts/containers/PitchMovementContainer';
import PitchLocationContainer from '../charts/containers/PitchLocationContainer';
import GameReviewInfoAndToggles from './GameReviewInfoAndToggles';
import PitchLegendMix from '../live/containers/PitchLegendMix';
import ReleasePointContainer from '../charts/containers/ReleasePointContainer';

interface GameReviewDataProps {
  gumbo: Gumbo;
  playIndex: number;
  setPlayIndex: (index: number) => void;
  eventIndex: number;
  setEventIndex: (index: number) => void;
  pitcherData: StatcastRawPitcherData;
  batterData: StatcastRawBatterData;
  batterPov: boolean;
  setBatterPov: (value: boolean) => void;
  handednessFilter: boolean;
  setHandednessFilter: (value: boolean) => void;
  countFilter: boolean;
  setCountFilter: (value: boolean) => void;
}

export default function GameReviewData(props: GameReviewDataProps) {

  const throws = determinePitcherThrows(props.pitcherData.map(x => x.p_throws));
  const stands = determineBatterStance(props.batterData.map(x => x.stand));

  const pitchTypesToColor = determineCommonPitches(props.pitcherData.map(x => x.pitch_type));

  const batterHandedness = props.handednessFilter ? stands : null;
  const pitcherHandedness = props.handednessFilter ? throws : null;

  const filteredPitcherData = filterVsBatterHandedness(props.pitcherData, batterHandedness);
  const filteredBatterData = filterVsPitcherHandedness(props.batterData, pitcherHandedness);

  const count = getGumboCount(props.gumbo, props.playIndex, props.eventIndex);
  const countFilter = props.countFilter ? count : undefined;

  return (
    <Box>
      <HStack>
        <GameReviewInfoAndToggles
          gumbo={props.gumbo}
          playIndex={props.playIndex}
          setPlayIndex={props.setPlayIndex}
          eventIndex={props.eventIndex}
          setEventIndex={props.setEventIndex}
          handednessFilter={props.handednessFilter}
          setHandednessFilter={props.setHandednessFilter}
          countFilter={props.countFilter}
          setCountFilter={props.setCountFilter}
          batterPov={props.batterPov}
          setBatterPov={props.setBatterPov}
        />
        <PitchLegendMix
          countFilter={countFilter}
          pitcherData={filteredPitcherData}
        />
        <PitchMovementContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <ReleasePointContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <SprayChartContainer
          batterData={filteredBatterData}
          pitchTypesToColor={pitchTypesToColor}
          countFilter={countFilter}
        />
      </HStack>
      <HStack>
        <PitchLocationContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <BatterSwingScatterContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={(filteredBatterData || [])}
          pitchTypesToColor={pitchTypesToColor}
        />
      </HStack>
    </Box>
  );
}
