import { Box, VStack } from '@chakra-ui/react';
import { Gumbo } from '../../shared/interfaces/gumboInterfaces';
import { gumboToLinescore } from '../../shared/utils/gumbo/gumboUtils';
import ScoreBug from '../live/ScoreBug';
import BatterPovToggle from '../selectors/BatterPovToggle';
import CountToggle from '../selectors/CountToggle';
import EventIndexSelector from '../selectors/EventIndexSelector';
import HandednessToggle from '../selectors/HandednessToggle';
import PlayIndexSelector from '../selectors/PlayIndexSelector';

interface GameReviewInfoAndTogglesProps {
  gumbo: Gumbo;
  playIndex: number;
  setPlayIndex: (index: number) => void;
  eventIndex: number;
  setEventIndex: (index: number) => void;
  batterPov: boolean;
  setBatterPov: (value: boolean) => void;
  handednessFilter: boolean;
  setHandednessFilter: (value: boolean) => void;
  countFilter: boolean;
  setCountFilter: (value: boolean) => void;
}

export default function GameReviewInfoAndToggles(props: GameReviewInfoAndTogglesProps) {

  const play = props.gumbo.liveData.plays.allPlays[props.playIndex];
  const linescore = gumboToLinescore(props.gumbo, props.playIndex, props.eventIndex);

  const numPlays = props.gumbo.liveData.plays.allPlays.length;
  const numEvents = play.playEvents.length;

  return (
    <VStack
      justify='space-between'
      width='190px'
      border='1px solid lightgrey'
      borderRadius='md'
    >
      <ScoreBug linescore={linescore} />
      <Box>Batter: {play.matchup.batter.fullName}</Box>
      <Box>Pitcher: {play.matchup.pitcher.fullName}</Box>
      <PlayIndexSelector
        value={props.playIndex}
        setValue={props.setPlayIndex}
        min={0}
        max={numPlays - 1}
      />
      <EventIndexSelector
        value={props.eventIndex}
        setValue={props.setEventIndex}
        min={0}
        max={numEvents - 1}
      />
      <BatterPovToggle batter={props.batterPov} setBatter={props.setBatterPov} />
      <HandednessToggle handednessFilter={props.handednessFilter} setHandednessFilter={props.setHandednessFilter} />
      <CountToggle countFilter={props.countFilter} setCountFilter={props.setCountFilter} />
    </VStack>
  );
}
