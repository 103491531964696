import { Box } from '@chakra-ui/react';
import useWindowSize from '../hooks/useWindowSize';

export default function Playground() {

  const windowSize = useWindowSize();

  return (
    <Box>
      {windowSize.width} x {windowSize.height}
    </Box>
  );
}
