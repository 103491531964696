export const dontDrawAxisOptions = {
  showgrid: false,
  zeroline: false,
  showline: false,
  ticks: '',
  showticklabels: false
};

export const zeroMargins = {
  l: 0,
  r: 0,
  b: 0,
  t: 0,
  pad: 0
};
