import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { getPitcherDataRows } from '../api/statcastApi';
import PitchMovement from '../components/charts/plotly/PitchMovement';
import { teamsAbbr } from '../shared/constants/statcast';
import SeasonTeamPlayerSelector from '../components/selectors/SeasonTeamPlayerSelector';
import BatterPovToggle from '../components/selectors/BatterPovToggle';
import Sidebar from '../components/nav/Sidebar';
import PitchMix from '../components/charts/plotly/PitchMix';
import PitchLocation from '../components/charts/wrappers/PitchLocation';
import { StatcastRawPitcherDataRows } from '../shared/interfaces/apiInterfaces';
import FilteredPitchLegend from '../components/charts/legends/FilteredPitchLegend';

function PitcherProfile() {
  const [pitcherData, setPitcherData] = useState<StatcastRawPitcherDataRows | null>(null);

  const [season, setSeason] = useState(2022);
  const [team, setTeam] = useState(teamsAbbr[0]);
  const [player, setPlayer] = useState<number | null>(null);
  const [batterView, setBatterView] = useState(true);

  useEffect(() => {
    if (player != null) {
      getPitcherDataRows(player, season).then(setPitcherData);
    }
  }, [season, player]);

  return (
    <Box display={{ base: 'block', md: 'flex' }} height='100%'>
      <Sidebar>
        <SeasonTeamPlayerSelector
          batters={false}
          season={season} onSeasonChange={setSeason}
          team={team} onTeamChange={setTeam}
          player={player || 0} onPlayerChange={setPlayer}
        />
        <BatterPovToggle batter={batterView} setBatter={setBatterView} />
      </Sidebar>
      <Box>
        {pitcherData && <FilteredPitchLegend pitch_type={pitcherData['pitch_type']} />}
      </Box>

      <Box height='100%'>
        {/* {pitcherData && <PitchMovement batterPov={batterView} data={pitcherData} />} */}
      </Box>
      <Box height='100%'>
        {pitcherData && <PitchMix pitch_type={pitcherData['pitch_type']} />}
      </Box>
      {/* <Box height='100%'>
        {pitcherData && <PitchLocation batterPov={batterView} data={pitcherData} />}
      </Box> */}
    </Box>
  );
}

export default PitcherProfile;
