import axios from 'axios';
import { StatcastRawBatterDataRows, StatcastRawPitcherDataRows, StatcastRawPitcherData, StatcastRawBatterData, StatcastPitcherAverages } from '../shared/interfaces/apiInterfaces';

export async function getPitcherDataRows(playerId: number, season?: number): Promise<StatcastRawPitcherDataRows> {
  const uri = `/api/statcast/pitcher/${playerId}`;
  const res = await axios.get(uri, { params: { season, format: 'rows' } });
  return res.data as StatcastRawPitcherDataRows;
}

export async function getPitcherDataRecords(playerId: number, season: number=2022): Promise<StatcastRawPitcherData> {
  const uri = `/api/statcast/pitcher/${playerId}`;
  const res = await axios.get(uri, { params: { season, format: 'records' } });
  return res.data as StatcastRawPitcherData;
}

export async function getBatterDataRows(playerId: number, season?: number): Promise<StatcastRawBatterDataRows> {
  const uri = `/api/statcast/batter/${playerId}`;
  const res = await axios.get(uri, { params: { season, format: 'rows' } });
  return res.data as StatcastRawBatterDataRows;
}

export async function getBatterDataRecords(playerId: number, season: number=2022): Promise<StatcastRawBatterData> {
  const uri = `/api/statcast/batter/${playerId}`;
  const res = await axios.get(uri, { params: { season, format: 'records' } });
  return res.data as StatcastRawBatterData;
}

export async function getStatcastPitcherAverages(playerId: number): Promise<StatcastPitcherAverages> {
  const uri = `/api/statcast/pitcher/${playerId}/averages`;
  const res = await axios.get(uri);
  return res.data as StatcastPitcherAverages;
}
