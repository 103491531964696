import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface SidebarProps {
  children: ReactNode;
}

export default function Sidebar(props: SidebarProps) {

  return (
    <Box
      width={{
        base: '100%',
        sm: '178px',
        md: '192px',
        lg: '200px',
      }}
      height={{
        sm: 'calc(100vh - 76px)'
      }}
      flex={{
        sm: '0 0 178px',
        md: '0 0 192px',
        lg: '0 0 200px',
      }}
      borderRight={{
        sm: '1px solid #d2d2d2'
      }}
      borderBottom={{
        base: '1px solid #d2d2d2',
        sm: 'none'
      }}
      padding={{
        base: '5px 8px',
        sm: '8px 2px',
        md: '8px 4px',
        lg: '8px',
      }}
    >
      {props.children}
    </Box>
  );
}
