import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { getStatsApiSchedule } from '../api/liveApi';
import Scoreboard from '../components/live/scoreboard/Scoreboard';
import { ExtendedStatsApiSchedule } from '../shared/interfaces/apiInterfaces';

const updateScheduleInterval = 10 * 60 * 1000;

export default function LiveSchedule() {

  const [schedule, setSchedule] = useState<ExtendedStatsApiSchedule[] | null>(null);

  function updateSchedule() {
    getStatsApiSchedule().then(setSchedule);
  }

  useEffect(() => {
    updateSchedule();
    const interval = setInterval(updateSchedule, updateScheduleInterval);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box height='100%' width='100%'>
      {schedule == null
      ? <Box w='100%' marginTop={3}>Loading schedule...</Box>
      : <Scoreboard schedule={schedule || []} />
      }
    </Box>
  );
}
