import NumberRadio from './base/NumberRadio';

interface BallsRadioProps {
  balls: number;
  setBalls: (value: number) => void;
}

export default function BallsRadio(props: BallsRadioProps) {

  return (
    <NumberRadio label={'Balls'} values={[0, 1, 2, 3]} setValue={props.setBalls} value={props.balls} />
  );
}
