import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import { dontDrawAxisOptions, zeroMargins } from '../../../shared/constants/plotly';
import { StatcastRawBatterData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import { filterBatterDataByExactCount, filterBatterDidSwingAndMiss } from '../../../shared/utils/filterUtils';
import { blackStrikeZone } from '../../../shared/utils/plotUtils';
import { pitchToColor } from '../../../shared/constants/statcast';

interface BatterSwingMissScatterProps {
  data: StatcastRawBatterData;
  batterPov: boolean;
  countFilter?: Count;
  pitchTypesToColor?: string[];
}

export default function BatterSwingMissScatter(props: BatterSwingMissScatterProps) {

  const filteredSwing = filterBatterDidSwingAndMiss(props.data);
  const filtered = filterBatterDataByExactCount(filteredSwing, props.countFilter);
  const toColor = props.pitchTypesToColor == null ? [] : props.pitchTypesToColor;

  const color = filtered.map(x => toColor.includes(x.pitch_type) ? pitchToColor(x.pitch_type) : 'darkgrey');

  const x = props.batterPov ? filtered.map(x => x.plate_x) : filtered.map(x => x.plate_x * -1);

  const yaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    zeroline: true,
    zerolinecolor: '#af683c',
    zerolinewidth: 2,
    range: [0, 5],
    constrain: 'domain',
    scaleanchor: 'x',
    scaleratio: 1,
  } as Partial<Plotly.LayoutAxis>;

  const xaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    range: [-2, 2],
    constrain: 'domain',
  } as Partial<Plotly.LayoutAxis>;

  const shapes = [blackStrikeZone];

  return (
    <Plot
      data={[
        {
          x,
          y: filtered.map(x => x.plate_z),
          type: 'scattergl',
          mode: 'markers',
          opacity: 1,
          marker: { color },
        }
      ]}
      layout={ {
        yaxis,
        xaxis,
        shapes,
        autosize: true,
        margin: zeroMargins
      } }
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      config={{ staticPlot: true }}
    />
  );

}
