import NumberInputStep1 from './base/NumberInputStep1';

interface PlayIndexSelectorProps {
  value: number;
  setValue: (value: number) => void;
  min: number;
  max: number;
}

export default function PlayIndexSelector(props: PlayIndexSelectorProps) {
  return (
    <NumberInputStep1
      value={props.value}
      setValue={props.setValue}
      min={props.min}
      max={props.max}
      name='Play'
    />
  );
}
