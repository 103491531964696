import axios from 'axios';
import { TeamPlayers, GameScore } from '../shared/interfaces/apiInterfaces';
import { Gumbo } from '../shared/interfaces/gumboInterfaces';

export async function getTeamBatters(season?: number): Promise<TeamPlayers> {
  const uri = '/api/team-batters';
  const res = await axios.get(uri, { params: { season } });
  return res.data as TeamPlayers;
}

export async function getTeamPitchers(season?: number): Promise<TeamPlayers> {
  const uri = `/api/team-pitchers`;
  const res = await axios.get(uri, { params: { season } });
  return res.data as TeamPlayers;
}

export async function getTeamGameScores(teamId: number, season: number=2022): Promise<GameScore[]> {
  const uri = `/api/team/${teamId}/scores`;
  const res = await axios.get(uri, { params: { season, format: 'records' } });
  return res.data as GameScore[];
}

export async function getGumbo(gamePk: number): Promise<Gumbo> {
  const uri = `/api/statsapi/gumbo/${gamePk}`;
  const res = await axios.get(uri);
  return res.data as Gumbo;
}

export async function saveFrontendLog(data: any): Promise<void> {
  const uri = '/api/log/frontend';
  const logData = typeof data !== 'object' ? { message: data } : data;
  await axios.post(uri, logData);
}
