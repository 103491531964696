import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { StatcastRawBatterData } from '../shared/interfaces/apiInterfaces';
import { getBatterDataRecords } from '../api/statcastApi';
import { teamsAbbr } from '../shared/constants/statcast';
import SeasonTeamPlayerSelector from '../components/selectors/SeasonTeamPlayerSelector';
import BatterPovToggle from '../components/selectors/BatterPovToggle';
import Sidebar from '../components/nav/Sidebar';
import SprayChart from '../components/charts/plotly/SprayChart';
import LaunchAngleBoxplot from '../components/charts/plotly/LaunchAngleBoxplot';
import BatterSwingHeatmap from '../components/charts/wrappers/BatterSwingHeatmap';

function BatterProfile() {
  const [batterData, setBatterData] = useState<StatcastRawBatterData | null>(null);

  const [season, setSeason] = useState(2022);
  const [team, setTeam] = useState(teamsAbbr[0]);
  const [player, setPlayer] = useState<number | null>(null);
  const [batterView, setBatterView] = useState(true);

  useEffect(() => {
    if (player != null) {
      getBatterDataRecords(player, season).then(setBatterData);
    }
  }, [season, player]);

  return (
    <Box display={{ base: 'block', md: 'flex' }} height='100%'>
      <Sidebar>
        <SeasonTeamPlayerSelector
          batters={true}
          season={season} onSeasonChange={setSeason}
          team={team} onTeamChange={setTeam}
          player={player || 0} onPlayerChange={setPlayer}
        />
        <BatterPovToggle batter={batterView} setBatter={setBatterView} />
      </Sidebar>
      <Box height='100%'>
        {batterData && <SprayChart batterData={batterData} />}
      </Box>
      <Box height='100%'>
        {batterData && <LaunchAngleBoxplot launch_angle={batterData.map(x => x.launch_angle)} type={batterData.map(x => x.type)} />}
      </Box>
      <Box height='100%'>
        {batterData && <BatterSwingHeatmap batterPov={batterView} data={batterData} />}
      </Box>
    </Box>
  );
}

export default BatterProfile;
