import { Box, HStack } from '@chakra-ui/react';
import { pitchToColor, pitchToName } from '../../../shared/constants/statcast';
import { orderPitches } from '../../../shared/utils/filterUtils';

interface PitchLegendProps {
  pitches: string[];
}

export default function PitchLegend(props: PitchLegendProps) {

  // TODO(?): use shorter names (e.g. Splitter, 4-Seamer etc.)

  const orderedPitches = orderPitches(props.pitches);

  return (
    <Box
      border='1px solid' borderRadius='md' borderColor='gray.500'
    >
      {orderedPitches.map((pitch) => {
        return (
          <HStack key={pitch} paddingLeft={2} paddingRight={2}>
            <Box w='15px' h='15px' bg={pitchToColor(pitch)} />
            <Box>{pitchToName(pitch)}</Box>
          </HStack>
        );
      })}
    </Box>
  );
}
