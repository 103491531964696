import { StatcastRawBatterData } from '../../../shared/interfaces/apiInterfaces';
import { filterBatterDidSwing } from '../../../shared/utils/filterUtils';
import PlatePitchHeatmap from '../plotly/PlatePitchHeatmap';

interface BatterSwingHeatmapProps {
  data: StatcastRawBatterData;
  batterPov: boolean;
  title?: string;
}

export default function BatterSwingHeatmap(props: BatterSwingHeatmapProps) {

  const filtered = filterBatterDidSwing(props.data);

  return (
    <PlatePitchHeatmap
      batterPov={props.batterPov}
      plate_x={filtered.map(x => x.plate_x)}
      plate_z={filtered.map(x => x.plate_z)}
      title={props.title}
      showScale={false}
    />
  );
}
