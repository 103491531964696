import _ from 'lodash';
import { Gumbo, GumboDiffPatch, GumboDiffPatchReponse } from '../../interfaces/gumboInterfaces';
import { isObject, parseInt2 } from '../utils';

export function patchGumbo(gumbo: Gumbo, patchResponse: GumboDiffPatchReponse): boolean {
  for (const patch of patchResponse) {
    if (!applyPatch(gumbo, patch)) {
      return false;
    }
  }
  return true;
}

export function applyPatchPure(gumbo: Gumbo, patch: GumboDiffPatch): Gumbo | undefined {
  const newGumbo = _.cloneDeep(gumbo);
  const success = applyPatch(newGumbo, patch);
  if (success) {
    return newGumbo;
  } else {
    return;
  }
}

export function applyPatch(gumbo: Gumbo, patch: GumboDiffPatch): boolean {
  for (const d of patch.diff || []) {
    try {
      const value = d?.value;
      const op = d?.op;
      const path = d?.path.split('/');
      let target: any = gumbo;

      if (op == null) {
        continue;
      }
      if (value == null && op !== 'remove') {
        continue;
      }

      for (const [i, p] of path.slice(1).entries()) {
        const idx = parseInt2(p);

        if (Array.isArray(target) && idx == null) {
          // log error
          return false;
        }

        if (i === path.length - 2) {
          // end of path -- set the value
          if (op === 'add') {
            if (Array.isArray(target)) {
              target.push(value);
              continue;
            } else if (isObject(target)) {
              target[p] = value;
              continue;
            }
          } else if (op === 'remove') {
            try {
              if (Array.isArray(target)) {
                if (idx != null && idx < target.length) {
                  target.splice(idx, 1);
                } else {
                  // log warning
                }
              } else if (isObject(target)) {
                if (p in target) {
                  delete target[p];
                } else {
                  // log warning
                }
              }
            } catch (error) {
              // log error
            }
            continue;
          } else if (op === 'replace') {
            if (Array.isArray(target)) {
              if (idx != null && target.length > 0 && target.length > idx) {
                target[idx] = value;
              } else if (target.length === idx) {
                target.push(value);
              } else {
                // log warning. list not long enough to replace at that index
              }
            } else {
              target[p] = value;
            }
            continue;
          }
        } else if ((isObject(target) && !(p in target)) || (idx != null && Array.isArray(target) && target.length <= idx)) {
          // key does not exist

          if (parseInt2(path[i + 1]) != null) {
            // TODO: don't use this? it shouldn't work in the python version
            // next hop is a list

            if (Array.isArray(target)) {
              if (target.length === idx) {
                target.push([]);
              } else {
                // log warning. list not log enough to append index idx
              }
            } else {
              target[p] = [];
            }
          } else if (idx === path.length - 3 && op === 'add') {
            // next hop is the target key to add
            // do nothing, because it will be handled on the next loop
          } else {
            // next hop is a dict

            if (Array.isArray(target)) {
              if (target.length === idx) {
                target.push({});
              } else {
                // log warning. list not log enough to append index idx
              }
            } else {
              target[p] = {};
            }
          }
        }

        // point to next key in the path
        target = target[idx || p];
      }
    } catch (error) {
      // log error
      return false;
    }
  }
  return true;
}

