import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { StatcastRawBatterData, StatcastRawPitcherData, StatsApiLinescoreResponse } from '../shared/interfaces/apiInterfaces';
import { getBatterDataRecords, getPitcherDataRecords } from '../api/statcastApi';
import { createFakeLinescoreResponse } from '../shared/constants/dummyData';
import Sidebar from '../components/nav/Sidebar';
import BatterPovToggle from '../components/selectors/BatterPovToggle';
import TeamPlayerSelector from '../components/selectors/TeamPlayerSelector';
import StrikesRadio from '../components/selectors/StrikesRadio';
import BallsRadio from '../components/selectors/BallsRadio';
import LiveGameData from '../components/live/LiveGameData';

export default function Matchup() {

  const [delay, setDelay] = useState(25);
  const [batterPov, setBatterPov] = useState(true);
  const [handednessFilter, setHandednessFilter] = useState(false);
  const [countFilter, setCountFilter] = useState(false);

  const [balls, setBalls] = useState(0);
  const [strikes, setStrikes] = useState(0);

  const [teamOffense, setTeamOffense] = useState('PHI');
  const [teamDefense, setTeamDefense] = useState('HOU');

  const [batter, setBatter] = useState<number | null>(null);
  const [pitcher, setPitcher] = useState<number | null>(null);

  const [pitcherData, setPitcherData] = useState<StatcastRawPitcherData>([]);
  const [batterData, setBatterData] = useState<StatcastRawBatterData>([]);

  const [linescore, setLinescore] = useState<StatsApiLinescoreResponse | null>(null);


  useEffect(() => {
    // TODO: use real batter and pitcher names
    const fakeLinescore = createFakeLinescoreResponse(pitcher!, batter!, balls, strikes);
    setLinescore(fakeLinescore);
  }, [pitcher, batter, balls, strikes]);

  useEffect(() => {
    if (pitcher != null) {
      getPitcherDataRecords(pitcher).then(setPitcherData);
    }
  }, [pitcher]);

  useEffect(() => {
    if (batter != null) {
      getBatterDataRecords(batter).then(setBatterData);
    }
  }, [batter]);


  return (
    <Box display={{ base: 'block', md: 'flex' }} height='100%'>
      <Sidebar>
        <TeamPlayerSelector
          batters={false}
          season={2022}
          team={teamDefense} onTeamChange={setTeamDefense}
          player={pitcher || 0} onPlayerChange={setPitcher}
        />
        <TeamPlayerSelector
          batters={true}
          season={2022}
          team={teamOffense} onTeamChange={setTeamOffense}
          player={batter || 0} onPlayerChange={setBatter}
        />
        <BatterPovToggle batter={batterPov} setBatter={setBatterPov} />
        <StrikesRadio strikes={strikes} setStrikes={setStrikes} />
        <BallsRadio balls={balls} setBalls={setBalls} />
      </Sidebar>
      {linescore != null &&
      <Box>
        <LiveGameData
          delay={delay}
          setDelay={setDelay}
          batterPov={batterPov}
          setBatterPov={setBatterPov}
          handednessFilter={handednessFilter}
          setHandednessFilter={setHandednessFilter}
          countFilter={countFilter}
          setCountFilter={setCountFilter}
          linescoreResponse={linescore}
          pitcherData={pitcherData}
          batterData={batterData}
        />
      </Box>
      }
    </Box>
  );
}
