import { Box, Heading, VStack } from '@chakra-ui/react';
import DataSources from '../components/about/DataSources';
import SiteDescription from '../components/about/SiteDescription';

export default function HomePage() {

  return (
    <VStack>
      <Heading>The Fifth Seam - A better grip on baseball data</Heading>
      <SiteDescription />
      <DataSources />
    </VStack>
  );
}
