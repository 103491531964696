import { useEffect, useState } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { StatcastRawBatterData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import SprayChart from '../plotly/SprayChart';
import PitchCheckboxGroup from '../../selectors/PitchCheckboxGroup';
import React from 'react';

interface SprayChartContainerProps {
  batterData: StatcastRawBatterData;
  pitchTypesToColor: string[];
  countFilter?: Count;
}

function SprayChartContainer(props: SprayChartContainerProps) {

  const allPitchTypes = props.pitchTypesToColor.concat('other');

  const [pitchesToDisplay, setPitchesToDisplay] = useState(allPitchTypes);

  /* TODO: fix the dependencies array here.
  For some reason it wasn't working correctly using using the `props.pitchTypesToColor` value.
  So I added the JSON.stringify thing to get it work correctly.
  But now react is giving a warning.
  */
  useEffect(() => {
    setPitchesToDisplay(props.pitchTypesToColor.concat('other'));
  }, [JSON.stringify(props.pitchTypesToColor)]);

  return (
    <VStack
      justify='space-between'
      border='1px solid lightgrey'
      borderRadius='md'
      width='354px'
    >
      <HStack spacing={8}>
        <Box>
          Spray Chart
        </Box>
        <PitchCheckboxGroup
          allPitchTypes={allPitchTypes}
          values={pitchesToDisplay}
          setValues={setPitchesToDisplay}
        />
      </HStack>
      <Box height='350px' width='350px'>
        <SprayChart
          batterData={props.batterData}
          countFilter={props.countFilter}
          pitchTypesToDisplay={pitchesToDisplay}
          pitchTypesToColor={props.pitchTypesToColor}
        />
      </Box>
    </VStack>
  );
}

export default React.memo(SprayChartContainer);
