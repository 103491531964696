import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

interface DelayPickerProps {
  delay: number;
  setDelay: (value: number) => void;
}

export default function DelayPicker(props: DelayPickerProps) {
  return (
    <NumberInput size='md' maxW={24}
    value={props.delay}
    onChange={(_, newDelay) => props.setDelay(newDelay)}
    min={0}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
}
