import { Box } from '@chakra-ui/react';
import ReactJson from 'react-json-view'
import { PlayEvent } from '../../shared/interfaces/gumboInterfaces';

interface GumboEventJsonViewerProps {
  event: PlayEvent;
}

export default function GumboEventJsonViewer(props: GumboEventJsonViewerProps) {

  return (
    <Box w='500px' h='815px' textAlign='left' overflow='scroll'>
      <ReactJson
        src={props.event}
        collapsed={1}
        displayDataTypes={false}
      />
    </Box>
  );
}
