import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import _ from 'lodash';
import { genLoessTrace } from '../../../shared/utils/plotUtils';

export interface RelativePitcherFatigueProps {
  x: number[];
  y: number[];
  color: string[];
  normal?: number[];
}

export default function RelativePitcherFatigue(props: RelativePitcherFatigueProps) {

  const loessTrace = {
    ...genLoessTrace(props.x, props.y, 'black', 0.5),
    hoverinfo: 'none',
  } as Plotly.Data;

  const data: Plotly.Data[] = [
    {
      x: props.x,
      y: props.y,
      type: 'scattergl',
      mode: 'markers',
      opacity: 1,
      marker: { color: props.color },
    },
    loessTrace
  ];

  if (props.normal != null) {
    const xNormal = _.range(1, props.normal.length + 1);
    const trace = {
      x: xNormal.slice(0, props.x.length),
      y: props.normal.slice(0, props.x.length),
      mode: 'lines',
      marker: { color: 'red' },
      hoverinfo: 'none',
    }
    data.push(trace);
  }

  const yaxis: Partial<Plotly.LayoutAxis> = {
    tickformat: '.0%',
    fixedrange: true,
  } as Partial<Plotly.LayoutAxis>;

  const xaxis: Partial<Plotly.LayoutAxis> = {
    title: 'Pitch Count',
    zeroline: false,
    fixedrange: true,
  } as Partial<Plotly.LayoutAxis>;

  const margin = {
    t: 5
  };

  return (
    <Plot
      data={data}
      layout={ {
        yaxis,
        xaxis,
        width: 600,
        autosize: true,
        showlegend: false,
        margin,
      } }
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      config={{ displayModeBar: false }}
    />
  );

}
