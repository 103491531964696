import Plot from 'react-plotly.js';
import { zeroMargins } from '../../../shared/constants/plotly';
import { pitchToColor } from '../../../shared/constants/statcast';
import { StatcastRawPitcherData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import { filterPitcherDataByExactCount } from '../../../shared/utils/filterUtils';

interface PitchMovementProps {
  data: StatcastRawPitcherData;
  batterPov: boolean;
  countFilter?: Count;
}

export default function PitchMovement(props: PitchMovementProps) {

  const filtered = filterPitcherDataByExactCount(props.data, props.countFilter);

  const colors = filtered.map(x => pitchToColor(x.pitch_type));

  const x = props.batterPov ? filtered.map(x => x.pfx_x) : filtered.map(x => x.pfx_x * -1);

  const margin = {
    ...zeroMargins,
    l: 22,
    b: 22,
  };

  return (
    <Plot
      data={[
        {
          x,
          y: filtered.map(x => x.pfx_z),
          type: 'scattergl',
          mode: 'markers',
          opacity: 0.5,
          marker: { color: colors },
        }
      ]}
      layout={ {
        yaxis: {
          range: [-2, 2],
          tickvals: [-1, 0, 1],
          ticktext: ['-1 ft', '0', '1 ft'],
          scaleanchor: 'x',
          scaleratio: 1
        },
        xaxis: {
          range: [-2, 2],
          tickvals: [-1, 0, 1],
          ticktext: ['-1 ft', '0', '1 ft'],
          constrain: 'domain',
        },
        autosize: true,
        margin
      } }
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      config={{ staticPlot: true }}
    />
  );
}
