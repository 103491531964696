import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { StatcastRawBatterData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import BatterSwingScatter from '../plotly/BatterSwingScatter';
import BatterSwingMissScatter from '../plotly/BatterSwingMissScatter';

interface BatterSwingScatterContainerProps {
  data: StatcastRawBatterData;
  countFilter?: Count;
  batterPov: boolean;
  pitchTypesToColor?: string[];
}

function BatterSwingScatterContainer(props: BatterSwingScatterContainerProps) {

  return (
    <Tabs
      size='sm'
      width='335px'
      height='470px'
      border='1px solid lightgrey'
      borderRadius='md'
      isLazy
    >
      <TabList>
        <Tab>Batter Swing</Tab>
        <Tab>Batter Swing and Miss</Tab>
      </TabList>
      <TabPanels>
        <TabPanel padding={0}>
          <BatterSwingScatter
            batterPov={props.batterPov}
            countFilter={props.countFilter}
            data={props.data}
          />
        </TabPanel>
        <TabPanel padding={0}>
          <BatterSwingMissScatter
            batterPov={props.batterPov}
            countFilter={props.countFilter}
            data={props.data}
            pitchTypesToColor={props.pitchTypesToColor}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default React.memo(BatterSwingScatterContainer);
