import { GumboUpdate, StatsApiLinescore } from '../../interfaces/apiInterfaces';
import { Count, EventsByPitch, PitcherEvents, PitchEvent } from '../../interfaces/gameInterfaces';
import { Gumbo, Play } from '../../interfaces/gumboInterfaces';
import { PlayerInfo } from '../../interfaces/statsapiInterfaces';

export function gumboTimestampToDateTime(timestamp: string): Date {
  const datePart = timestamp.slice(0, 8);
  const timePart = timestamp.slice(9, 15);

  const year = Number(datePart.slice(0, 4));
  const month = Number(datePart.slice(4, 6)) - 1;
  const day = Number(datePart.slice(6, 8));

  const hour = Number(timePart.slice(0, 2));
  const minute = Number(timePart.slice(2, 4));
  const second = Number(timePart.slice(4, 6));

  return new Date(Date.UTC(year, month, day, hour, minute, second));
}

export function dateToGumboTimestamp(dt: Date): string {
  const timestamp = dt.toISOString().replace('T', '_').replace('Z', '');
  return timestamp;
}

export function removeGumboPure(update: GumboUpdate): GumboUpdate {
  if (update == null) {
    return update;
  }
  const { gumbo, ...rest } = update;
  return rest;
}

export function getLiveGumboCount(gumbo: Gumbo): Count {
  const play = gumbo.liveData.plays.currentPlay;
  const event = play.playEvents[play.playEvents.length - 1];
  return {
    balls: event.count.balls,
    strikes: event.count.strikes
  };
}

export function getGumboCount(gumbo: Gumbo, playIndex: number, eventIndex: number): Count {
  const play = gumbo.liveData.plays.allPlays[playIndex];
  const event = play.playEvents[eventIndex];
  return {
    balls: event.count.balls,
    strikes: event.count.strikes
  };
}

export function getMatchupInfoFromPlay(play: Play) {
  const { batter, pitcher, batSide, pitchHand } = play.matchup;
  return {
    batter: {
      id: batter.id,
      name: batter.fullName,
      handedness: batSide.code
    },
    pitcher: {
      id: pitcher.id,
      name: pitcher.fullName,
      handedness: pitchHand.code
    }
  };
}

export function getPlay(gumbo: Gumbo, playIndex: number): Play {
  return gumbo.liveData.plays.allPlays[playIndex];
}

export function getPitchers(gumbo: Gumbo): { away: PlayerInfo[]; home: PlayerInfo[]; } {
  const away: PlayerInfo[] = [];
  const home: PlayerInfo[] = [];
  for (const play of gumbo.liveData.plays.allPlays) {
    const pitcher = play.matchup.pitcher;
    if (play.about.isTopInning) {
      if (home[-1]?.id !== pitcher.id) {
        home.push(pitcher);
      }
    } else {
      if (away[-1]?.id !== pitcher.id) {
        away.push(pitcher);
      }
    }
  }
  return { away, home };
}

export function getGroupedPitcherEvents(pitcherId: number, gumbo: Gumbo): PitcherEvents | undefined {
  const grouped = groupEventsByPitcherPitchType(gumbo);
  for (const events of grouped) {
    if (events.pitcherId === pitcherId) {
      return events;
    }
  }
}

export function getPitcherEvents(pitcherId: number, gumbo: Gumbo): PitchEvent[] {
  let pitcherEvents: PitchEvent[] = [];
  let pitchCount = 0;

  for (const play of gumbo.liveData.plays.allPlays) {
    if (pitcherId === play.matchup.pitcher.id) {
      for (const event of play.playEvents) {
        if (event.isPitch) {
          pitchCount++;
          const pitchType = event.details.type.code;
          const pitchEvent = {
            pitchNumber: pitchCount,
            pitchType,
            event
          };
          pitcherEvents.push(pitchEvent);
        }
      }
    }
  }

  return pitcherEvents;
}

export function groupEventsByPitcherPitchType(gumbo: Gumbo): PitcherEvents[] {
  let pitcherEvents: PitcherEvents[] = [];

  let pitchCount = 0;
  let currentPitcherId: number | null = null;
  let eventsByPitch: EventsByPitch = {};

  for (const play of gumbo.liveData.plays.allPlays) {
    const pitcherId = play.matchup.pitcher.id;
    if (pitcherId == null) {
      continue;
    }
    if (pitcherId !== currentPitcherId) {
      if (currentPitcherId != null) {
        // We need to add the events for the previous pitcher.
        pitcherEvents.push({
          pitcherId: currentPitcherId,
          eventsByPitch
        });
        eventsByPitch = {};
      }
      currentPitcherId = pitcherId;
      pitchCount = 0;
    }
    for (const event of play.playEvents) {
      if (event.isPitch) {
        pitchCount++;
        const pitchType = event.details.type.code;
        const pitchEvent = {
          pitchNumber: pitchCount,
          pitchType,
          event
        };
        if (pitchType in eventsByPitch) {
          eventsByPitch[pitchType].push(pitchEvent);
        } else {
          eventsByPitch[pitchType] = [pitchEvent];
        }
      }
    }
  }

  // We still need to add the events for the last pitcher in the game.
  if (Object.entries(eventsByPitch).length > 0 && currentPitcherId != null) {
    // The non-null check is to narrow the type to number for the type checker.
    pitcherEvents.push({
      pitcherId: currentPitcherId,
      eventsByPitch
    });
  }

  return pitcherEvents;
}


export function liveGumboToLinescore(gumbo: Gumbo): StatsApiLinescore {
  const linescore = gumbo.liveData.linescore;
  const bases = [
    linescore.offense.first ? '1' : '0',
    linescore.offense.second ? '1' : '0',
    linescore.offense.third ? '1' : '0',
  ].join('');
  const received_at = new Date().toISOString();
  const response_id = 0;

  const statsApiLinescore: StatsApiLinescore = {
    away_errors: linescore.teams.away.errors,
    away_hits: linescore.teams.away.hits,
    away_lob: linescore.teams.away.leftOnBase,
    away_runs: linescore.teams.away.runs,
    away_team_id: gumbo.gameData.teams.away.id,
    away_team_name: gumbo.gameData.teams.away.name,
    balls: linescore.balls,
    bases,
    batter_id: linescore.offense.batter.id,
    batter_name: linescore.offense.batter.fullName,
    catcher_id: linescore.defense.catcher.id,
    catcher_name: linescore.defense.catcher.fullName,
    center_id: linescore.defense.center.id,
    center_name: linescore.defense.center.fullName,
    first_id: linescore.defense.first.id,
    first_name: linescore.defense.first.fullName,
    game_pk: gumbo.gameData.game.pk,
    home_errors: linescore.teams.home.errors,
    home_hits: linescore.teams.home.hits,
    home_lob: linescore.teams.home.leftOnBase,
    home_runs: linescore.teams.home.runs,
    home_team_id: gumbo.gameData.teams.home.id,
    home_team_name: gumbo.gameData.teams.home.name,
    in_hole_id: linescore.offense.inHole.id,
    in_hole_name: linescore.offense.inHole.fullName,
    inning: linescore.currentInning,
    is_top_inning: linescore.isTopInning ?? false,
    left_id: linescore.defense.left.id,
    left_name: linescore.defense.left.fullName,
    on_deck_id: linescore.offense.onDeck.id,
    on_deck_name: linescore.offense.onDeck.fullName,
    outs: linescore.outs,
    pitcher_id: linescore.defense.pitcher.id,
    pitcher_name: linescore.defense.pitcher.fullName,
    response_id,
    received_at,
    right_id: linescore.defense.right.id,
    right_name: linescore.defense.right.fullName,
    runner_1b_id: linescore.offense.first?.id || null,
    runner_1b_name: linescore.offense.first?.fullName || null,
    runner_2b_id: linescore.offense.second?.id || null,
    runner_2b_name: linescore.offense.second?.fullName || null,
    runner_3b_id: linescore.offense.third?.id || null,
    runner_3b_name: linescore.offense.third?.fullName || null,
    second_id: linescore.defense.second.id,
    second_name: linescore.defense.second.fullName,
    shortstop_id: linescore.defense.shortstop.id,
    shortstop_name: linescore.defense.shortstop.fullName,
    strikes: linescore.strikes,
    third_id: linescore.defense.third.id,
    third_name: linescore.defense.third.fullName,
  };

  return statsApiLinescore;
}

function getCurrentBatterStance(gumbo: Gumbo): string | null {
  const currentBatterId = gumbo.liveData.linescore.offense.batter.id;
  return gumbo.gameData.players[currentBatterId]?.batSide?.code;
}

function getCurrentPitcherThrowingArm(gumbo: Gumbo): string | null {
  const currentPitcherId = gumbo.liveData.linescore.defense.pitcher.id;
  return gumbo.gameData.players[currentPitcherId]?.pitchHand?.code;
}



export function gumboToLinescore(gumbo: Gumbo, playIndex: number, eventIndex: number): StatsApiLinescore {
  // TODO: figure out how to determine current runners.
  // TODO: figure out how to determine fielders.

  const play = gumbo.liveData.plays.allPlays[playIndex];
  const event = play.playEvents[eventIndex];

  let homeRuns = 0;
  let awayRuns = 0;

  if (playIndex > 0) {
    const previousPlay = gumbo.liveData.plays.allPlays[playIndex - 1];
    homeRuns = previousPlay.result.homeScore;
    awayRuns = previousPlay.result.awayScore;
  }

  const awayStats = gumbo.liveData.boxscore.teams.away.teamStats;
  const homeStats = gumbo.liveData.boxscore.teams.home.teamStats;

  const linescore = {
    away_errors: awayStats.fielding.errors,
    away_hits: awayStats.batting.hits,
    away_lob: awayStats.batting.leftOnBase,
    away_runs: awayRuns,
    away_team_id: gumbo.gameData.teams.away.id,
    away_team_name: gumbo.gameData.teams.away.name,
    balls: event.count.balls,
    bases: '000', // TODO
    batter_id: play.matchup.batter.id,
    batter_name: play.matchup.batter.fullName,
    catcher_id: 0,
    catcher_name: 'TODO',
    center_id: 0,
    center_name: 'TODO',
    first_id: 0,
    first_name: 'TODO',
    game_pk: gumbo.gamePk,
    home_errors: homeStats.fielding.errors,
    home_hits: homeStats.batting.hits,
    home_lob: homeStats.batting.leftOnBase,
    home_runs: homeRuns,
    home_team_id: gumbo.gameData.teams.home.id,
    home_team_name: gumbo.gameData.teams.home.name,
    in_hole_id: 0,
    in_hole_name: 'TODO',
    inning: play.about.inning,
    is_top_inning: play.about.isTopInning,
    left_id: 0,
    left_name: 'TODO',
    on_deck_id: 0,
    on_deck_name: 'TODO',
    outs: event.count.outs,
    pitcher_id: play.matchup.pitcher.id,
    pitcher_name: play.matchup.pitcher.fullName,
    response_id: 0,
    received_at: '',
    right_id: 0,
    right_name: 'TODO',
    runner_1b_id: null,
    runner_1b_name: 'TODO',
    runner_2b_id: null,
    runner_2b_name: 'TODO',
    runner_3b_id: null,
    runner_3b_name: 'TODO',
    second_id: 0,
    second_name: 'TODO',
    shortstop_id: 0,
    shortstop_name: 'TODO',
    strikes: event.count.strikes,
    third_id: 0,
    third_name: 'TODO',
  };
  return linescore;
}
