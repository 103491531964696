import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { StatcastRawPitcherData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import FilteredPitchLegend from '../../charts/legends/FilteredPitchLegend';
import LivePitchMix from '../../charts/wrappers/live/LivePitchMix';

interface PitchLegendMixProps {
  pitcherData: StatcastRawPitcherData;
  countFilter?: Count;
}

function PitchLegendMix(props: PitchLegendMixProps) {

  return (
    <VStack
      justify='space-between'
      width='205px'
      border='1px solid lightgrey'
      borderRadius='md'
    >
      <FilteredPitchLegend pitch_type={props.pitcherData.map(x => x.pitch_type)} />
      <Box height='220px' width='200px'>
        <LivePitchMix
          pitcherData={props.pitcherData}
          countFilter={props.countFilter}
        />
      </Box>
    </VStack>
  );
}

export default React.memo(PitchLegendMix);
