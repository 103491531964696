import _ from 'lodash';

// Home plate is 17 inches wide.
const homePlateWidth = 17 / 12;
const kZoneLeft = - homePlateWidth / 2;
const kZoneRight = homePlateWidth / 2;
const kZoneTop = 3.5;
const kZoneBottom = 1.6;

export const kZone = {
  x0: kZoneLeft,
  y0: kZoneBottom,
  x1: kZoneRight,
  y1: kZoneTop,
};

export const fielderLocations = {
  first: [75, 100],
  second: [35, 140],
  third: [-75, 100],
  shortstop: [-35, 140],
  left: [-150, 250],
  center: [0, 300],
  right: [150, 250],
};

export const baseLocations = {
  first: [63.63, 63.63],
  second: [0, 127.27],
  third: [-63.63, 63.63],
};

const pitchInfo: { [key: string]: string[] } = {
  'FF': ['4-Seam Fastball', 'Fastball', '#D22D49'],
  'FC': ['Cutter'         , 'Fastball', '#933F2C'],
  'SI': ['Sinker'         , 'Fastball', '#B62170'],
  'FS': ['Split Finger'   , 'Fastball', '#3BACAC'],
  'CU': ['Curveball'      , 'Breaking', '#00D1ED'],
  'KC': ['Knuckle Curve'  , 'Breaking', '#6236CD'],
  'CS': ['Slow Curve'     , 'Breaking', '#0068FF'],
  'SC': ['Screwball'      , 'Breaking', '#60DB33'],
  'SL': ['Slider'         , 'Breaking', '#EEE716'],
  'ST': ['Sweeper'        , 'Breaking', '#DDB33A'],
  'SV': ['Slurve'         , 'Breaking', '#93AFD4'],
  'CH': ['Changeup'       , 'Changeup', '#1DBE3A'],
  'FO': ['Forkball'       , 'Changeup', '#55CCAB'],
  'EP': ['Eephus'         , 'Junk'    , '#000000'], // TODO: decide on color
  'KN': ['Knuckle Ball'   , 'Junk'    , '#3C44CD'],
};

export const pitchOrder = ['FF', 'FC', 'SI', 'FS', 'CU', 'KC', 'SC', 'SL', 'ST', 'SV', 'CH', 'FO', 'EP', 'KN'];

export const pitchColors = _.mapValues(pitchInfo, x => x[2]);

export const pitchToName = (pitch: string) => pitchInfo[pitch]?.[0];

export const pitchToCategory = (pitch: string) => pitchInfo[pitch]?.[1];

export const pitchToColor = (pitch: string) => pitchInfo[pitch]?.[2] || '#000000';


const pitchMetrics = {
  'release_spin_rate': 'Release spin rate',
  'release_extension': 'Release extension',
  'release_speed'    : 'Release speed',
  'pfx_x'            : 'Plate X',
  'pfx_z'            : 'Plate Y',
};


export const contactDescriptions = ['foul', 'foul_bunt', 'foul_pitchout', 'foul_tip',
  'hit_into_play', 'hit_into_play_no_out', 'hit_into_play_score',
  'pitchout_hit_into_play_score', 'swinging_strike_blocked'];

export const noContactDescriptions = ['missed_bunt', 'swinging_pitchout',
  'swinging_strike'];

export const swingDescriptions = contactDescriptions.concat(noContactDescriptions);


export const teamsAbbr = ['ARI', 'ATL', 'BAL', 'BOS', 'CHC', 'CIN', 'CLE', 'COL', 'CWS',
  'DET', 'HOU', 'KC', 'LAA', 'LAD', 'MIA', 'MIL', 'MIN', 'NYM', 'NYY', 'OAK',
  'PHI', 'PIT', 'SD', 'SEA', 'SF', 'STL', 'TB', 'TEX', 'TOR', 'WSH'];

export const statcastSeason = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014,
  2013, 2012, 2011, 2010, 2009, 2008];

const OhtaniId = 660271;
