import { ExtendedStatsApiSchedule, ParsedGameState, StatsApiLinescore, StatsApiSchedule } from '../interfaces/apiInterfaces';
import { Count } from '../interfaces/gameInterfaces';

export function getGameState(game: StatsApiSchedule): ParsedGameState {
  if (game.detailed_game_state.startsWith('Postponed')) {
    return 'Postponed';
  } else if (game.detailed_game_state.startsWith('Cancelled')) {
    return 'Cancelled';
  } else if (game.abstract_game_state === 'Final') {
    return 'Final';
  } else if (game.detailed_game_state.startsWith('Delayed')) {
    if (game.abstract_game_state === 'Live') {
      return 'DelayedLive';
    } else if (game.abstract_game_state === 'Preview') {
      return 'DelayedPreview';
    } else {
      return 'Delayed';
    }
  } else if (game.abstract_game_state === 'Preview') {
    return 'Preview';
  } else if (game.abstract_game_state === 'Live') {
    if (game.detailed_game_state === 'Warmup') {
      return 'Preview';
    }
    // if (game.game_date < new Date()) {
    //   return 'Preview';
    // }
    return 'Live';
  }
  // I think the above should handle all cases.

  // This date comparison is not working
  if (game.game_date < new Date()) {
    return 'Preview';
  }
  return 'Unknown';
}

export function shouldScoreBeDisplayed(game: ExtendedStatsApiSchedule): boolean {
  return ['Live', 'Final', 'DelayedLive'].includes(game.parsed_game_state);
}

export function advanceLinescoreHalfInning(linescore: StatsApiLinescore): StatsApiLinescore {
  if (linescore == null) {
    return linescore;
  }
  if (linescore.outs < 3) {
    return linescore;
  }
  if (isGameOver(linescore)) {
    return linescore;
  }

  return {
    ...linescore,
    is_top_inning: !linescore.is_top_inning,
    inning: linescore.is_top_inning ? linescore.inning : linescore.inning + 1,
    outs: 0,
    balls: 0,
    strikes: 0
  };
}

export function advanceLinescoreCount(linescore: StatsApiLinescore): StatsApiLinescore {
  if (linescore == null) {
    return linescore;
  }
  if (isGameOver(linescore)) {
    return linescore;
  }
  if (linescore.strikes < 3 && linescore.balls < 4) {
    return linescore;
  }

  return {
    ...linescore,
    balls: 0,
    strikes: 0
  };
}

export function advanceLinescore(linescore: StatsApiLinescore): StatsApiLinescore {
  return advanceLinescoreCount(advanceLinescoreHalfInning(linescore));
}

export function isGameOver(linescore: StatsApiLinescore): boolean {
  if (linescore.inning < 9) {
    return false;
  }
  if (linescore.home_runs === linescore.away_runs) {
    return false;
  }
  if ((!linescore.is_top_inning || (linescore.is_top_inning && linescore.outs === 3)) && linescore.home_runs > linescore.away_runs) {
    return true;
  }
  if (!linescore.is_top_inning && linescore.outs === 3 && linescore.away_runs > linescore.home_runs) {
    return true;
  }
  return false;
}

export function getAdvancedCount(linescore: StatsApiLinescore): Count {
  const advanced = advanceLinescore(linescore);
  return {
    balls: advanced.balls,
    strikes: advanced.strikes
  };
}
