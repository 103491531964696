import { Box, HStack } from '@chakra-ui/react';

export default function FatigueLoessLegend() {

  return (
    <Box
      border='1px solid'
      borderColor='gray.300'
      display='inline-block'
      paddingLeft={2}
      paddingRight={2}
    >
      <HStack>
        <Box w='20px' h='2px' bg='black' />
        <Box fontSize='12px'>
          Current game trendline
        </Box>
      </HStack>
      <HStack>
        <Box w='20px' h='2px' bg='red' />
        <Box fontSize='12px'>
          Typical game trendline
        </Box>
      </HStack>
    </Box>
  );
}
