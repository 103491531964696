import { useState, useEffect } from 'react';
import { getInitialWindowSize } from '../shared/utils/utils';

const debounceDelay = 250;

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getInitialWindowSize());

  const debounce = (f: () => void, delay: number): (() => void) => {
    let timer: NodeJS.Timeout | null;
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        f();
      }, delay);
    };
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, debounceDelay);

    window.addEventListener('resize', debouncedHandleResize);

    debouncedHandleResize();

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return windowSize;
}
