import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import { dontDrawAxisOptions, zeroMargins } from '../../../shared/constants/plotly';
import { blackStrikeZone, genPlateScatterBorder } from '../../../shared/utils/plotUtils';

export interface PlateScatterProps {
  x: number[];
  y: number[];
  color: string[];
  batterPov: boolean;
  pitchColorBorder?: boolean;
  margin?: Partial<Plotly.Margin>;
}

export default function PlateScatter(props: PlateScatterProps) {

  const x = props.batterPov ? props.x : props.x.map(x => x * -1);

  const yaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    zeroline: true,
    zerolinecolor: '#af683c',
    zerolinewidth: 2,
    range: [0, 5],
    constrain: 'domain',
    scaleanchor: 'x',
    scaleratio: 1,
  } as Partial<Plotly.LayoutAxis>;

  const xaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    range: [-2, 2],
    constrain: 'domain',
  } as Partial<Plotly.LayoutAxis>;

  const shapes = [blackStrikeZone];

  if (props.pitchColorBorder) {
    const coloredBorderShapes = genPlateScatterBorder(props.color[0], 1);
    for (const shape of coloredBorderShapes) {
      shapes.push(shape);
    }
  }

  const margin = props.margin || zeroMargins;

  return (
    <Plot
      data={[
        {
          x,
          y: props.y,
          type: 'scattergl',
          mode: 'markers',
          opacity: 0.5,
          marker: { color: props.color },
        }
      ]}
      layout={ {
        yaxis,
        xaxis,
        shapes,
        autosize: true,
        margin
      } }
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      config={{ staticPlot: true }}
    />
  );

}
