import { Checkbox, CheckboxGroup, HStack } from '@chakra-ui/react';
import { orderPitches } from '../../shared/utils/filterUtils';

interface PitchCheckboxGroupProps {
  allPitchTypes: string[];
  values: string[];
  setValues: (values: string[]) => void;
}

export default function PitchCheckboxGroup(props: PitchCheckboxGroupProps) {

  const orderedPitches = orderPitches(props.allPitchTypes).concat('other');

  return (
    <CheckboxGroup value={props.values} onChange={props.setValues}>
      <HStack spacing={1}>
        {orderedPitches.map(value => {
          if (value === 'other') {
            return (
              <Checkbox
                key={value}
                borderColor='gray'
                colorScheme='gray'
                value={value}
              />
            );
          }
          const colorScheme = `pitch.${value}`;
          const borderColor = `${colorScheme}.500`;
          return (
            <Checkbox
              key={value}
              borderColor={borderColor}
              colorScheme={colorScheme}
              value={value}
            />
          );
        })}
      </HStack>
    </CheckboxGroup>
  );
}
