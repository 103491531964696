import { Box, HStack } from '@chakra-ui/react'
import { GumboUpdate } from '../../shared/interfaces/apiInterfaces';
import { Gumbo } from '../../shared/interfaces/gumboInterfaces';
import { PlayerStatsStore } from '../../shared/classes/PlayerStatsStore';
import { DelayedLinescore, DelayedPlay } from '../../shared/interfaces/appGumboInterfaces';
import { getDelayedPlayInfo } from '../../shared/utils/gumbo/gumboTraversalUtils';
import { determineCommonPitches } from '../../shared/utils/calcUtils';
import { filterVsBatterHandedness, filterVsPitcherHandedness } from '../../shared/utils/filterUtils';
import BatterSwingScatterContainer from '../charts/containers/BatterSwingScatterContainer';
import SprayChartContainer from '../charts/containers/SprayChartContainer';
import PitchMovementContainer from '../charts/containers/PitchMovementContainer';
import PitchLocationContainer from '../charts/containers/PitchLocationContainer';
import ReleasePointContainer from '../charts/containers/ReleasePointContainer';
import PitchLegendMix from './containers/PitchLegendMix';
import GumboLiveInfoAndToggles from './containers/GumboLiveInfoAndToggles';
import { useContext } from 'react';
import WindowSizeContext from '../../context/WindowSizeContext';

interface LiveGameDashboardProps {
  gumbo: Gumbo;
  updates: GumboUpdate[];
  displayPlay: DelayedPlay;
  displayLinescore: DelayedLinescore;
  preparedData: PlayerStatsStore;
  delay: number;
  setDelay: (value: number) => void;
  batterPov: boolean;
  setBatterPov: (value: boolean) => void;
  handednessFilter: boolean;
  setHandednessFilter: (value: boolean) => void;
  countFilter: boolean;
  setCountFilter: (value: boolean) => void;
}

export default function LiveGameDashboard(props: LiveGameDashboardProps) {
  const windowSize = useContext(WindowSizeContext);

  const delayedInfo = getDelayedPlayInfo(props.gumbo, props.displayPlay);

  if (delayedInfo == null) {
    return (
      <Box>
        Waiting for game to start...
      </Box>
    );
  }

  const stands = delayedInfo.matchup.batter.handedness;
  const throws = delayedInfo.matchup.pitcher.handedness;
  const batterId = delayedInfo.matchup.batter.id;
  const pitcherId = delayedInfo.matchup.pitcher.id;

  const batterStatcastData = props.preparedData.getBattingStatcast(batterId);
  const pitcherStatcastData = props.preparedData.getPitchingStatcast(pitcherId);

  const pitchTypesToColor = determineCommonPitches(pitcherStatcastData.map(x => x.pitch_type));

  const batterHandedness = props.handednessFilter ? stands : null;
  const pitcherHandedness = props.handednessFilter ? throws : null;

  const filteredPitcherData = filterVsBatterHandedness(pitcherStatcastData, batterHandedness);
  const filteredBatterData = filterVsPitcherHandedness(batterStatcastData, pitcherHandedness);

  const count = delayedInfo.count;
  const countFilter = props.countFilter ? count : undefined;

  return (
    <Box>
      <HStack>
        <GumboLiveInfoAndToggles
          displayLinescore={props.displayLinescore}
          count={count}
          homeTeamId={props.gumbo.gameData.teams.home.id}
          awayTeamId={props.gumbo.gameData.teams.away.id}
          batterName={delayedInfo.matchup.batter.name}
          pitcherName={delayedInfo.matchup.pitcher.name}
          delay={props.delay}
          setDelay={props.setDelay}
          handednessFilter={props.handednessFilter}
          setHandednessFilter={props.setHandednessFilter}
          countFilter={props.countFilter}
          setCountFilter={props.setCountFilter}
          batterPov={props.batterPov}
          setBatterPov={props.setBatterPov}
        />
        <PitchLegendMix
          countFilter={countFilter}
          pitcherData={filteredPitcherData}
        />
        <PitchMovementContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <ReleasePointContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <SprayChartContainer
          batterData={filteredBatterData}
          pitchTypesToColor={pitchTypesToColor}
          countFilter={countFilter}
        />
      </HStack>
      <HStack>
        <PitchLocationContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <BatterSwingScatterContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={(filteredBatterData || [])}
          pitchTypesToColor={pitchTypesToColor}
        />
      </HStack>
    </Box>
  );
}
