import { Box, VStack } from '@chakra-ui/react';
import { DelayedLinescore } from '../../../shared/interfaces/appGumboInterfaces';
import { CountAndOuts } from '../../../shared/interfaces/gameInterfaces';
import BatterPovToggle from '../../selectors/BatterPovToggle';
import CountToggle from '../../selectors/CountToggle';
import HandednessToggle from '../../selectors/HandednessToggle';
import DelayPicker from '../DelayPicker';
import GumboScoreBug from '../GumboScoreBug';

interface GumboLiveInfoAndTogglesProps {
  displayLinescore: DelayedLinescore;
  count: CountAndOuts;
  homeTeamId: number;
  awayTeamId: number;
  batterName: string;
  pitcherName: string;
  delay: number;
  setDelay: (value: number) => void;
  batterPov: boolean;
  setBatterPov: (value: boolean) => void;
  handednessFilter: boolean;
  setHandednessFilter: (value: boolean) => void;
  countFilter: boolean;
  setCountFilter: (value: boolean) => void;
}

export default function GumboLiveInfoAndToggles(props: GumboLiveInfoAndTogglesProps) {

  return (
    <VStack
      justify='space-between'
      width='190px'
      border='1px solid lightgrey'
      borderRadius='md'
    >
      <GumboScoreBug
        displayLinescore={props.displayLinescore}
        count={props.count}
        homeTeamId={props.homeTeamId}
        awayTeamId={props.awayTeamId}
      />
      <DelayPicker delay={props.delay} setDelay={props.setDelay} />
      <Box>Batter: {props.batterName}</Box>
      <Box>Pitcher: {props.pitcherName}</Box>
      <BatterPovToggle batter={props.batterPov} setBatter={props.setBatterPov} />
      <HandednessToggle handednessFilter={props.handednessFilter} setHandednessFilter={props.setHandednessFilter} />
      <CountToggle countFilter={props.countFilter} setCountFilter={props.setCountFilter} />
    </VStack>
  );
}