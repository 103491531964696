import axios from 'axios';
import { ExtendedStatsApiSchedule, PreparedPlayerStats, StatsApiLinescoreResponse, StatsApiLinescoresResponse } from '../shared/interfaces/apiInterfaces';
import { getGameState } from '../shared/utils/gameUtils';

export async function getStatsApiSchedule(): Promise<ExtendedStatsApiSchedule[]> {
  const uri = `/api/statsapi/schedule`;
  const res = await axios.get(uri);
  if (!Array.isArray(res.data)) {
    return [];
  }
  const data = res.data.map(d => {
    d.game_date = new Date(d.game_date);
    d.received_at = new Date(d.received_at);
    d.parsed_game_state = getGameState(d);
    return d;
  });
  return data as ExtendedStatsApiSchedule[];
}

export async function getStatsApiLinescores(gamePk: number): Promise<StatsApiLinescoresResponse> {
  const uri = `/api/statsapi/linescores/${gamePk}`;
  const res = await axios.get(uri);
  return res.data as StatsApiLinescoresResponse;
}

export async function getStatsApiLinescore(gamePk: number): Promise<StatsApiLinescoreResponse> {
  const res = await getStatsApiLinescores(gamePk);
  return {
    game_state: res.game_state,
    linescore: res.linescores[0]
  };
}

export async function getPreparedStats(gamePk: number): Promise<PreparedPlayerStats> {
  const uri = `/api/game/${gamePk}/prepared`;
  const res = await axios.get(uri);
  return res.data as PreparedPlayerStats;
}
