import { Box, Heading, Link, Text } from '@chakra-ui/react';

export default function DataSources() {

  const chadwickRegister = (
    <Text>
      Contains information from the <Link href='https://github.com/chadwickbureau/register'>
        Chadwick Baseball Bureau Register
      </Link> which is made available under the <Link href='https://opendatacommons.org/licenses/by/1-0/'>
        Open Data Commons Attribution License
      </Link>
      .
    </Text>
  );

  const chadwickRetrosheet = (
    <Text>
      This site uses a version of the Retrosheet historical data (from <Link href='https://www.retrosheet.org'>
      https://www.retrosheet.org
      </Link>
      ) maintained by <Link href='https://chadwick-bureau.com/'>
      Chadwick Baseball Bureau
      </Link>
      .
    </Text>
  );

  const mlb = (
    <Text>
      Major League and Minor League Baseball data provided by Major League Baseball.
    </Text>
  );

  return (
    <Box>
      <Heading size='md'>Data Sources</Heading>
        {mlb}
        {chadwickRetrosheet}
        {chadwickRegister}
    </Box>
  );
}
