import NumberRadio from './base/NumberRadio';

interface StrikesRadioProps {
  strikes: number;
  setStrikes: (value: number) => void;
}

export default function StrikesRadio(props: StrikesRadioProps) {

  return (
    <NumberRadio label={'Strikes'} values={[0, 1, 2]} setValue={props.setStrikes} value={props.strikes} />
  );
}
