import { Box, VStack } from '@chakra-ui/react';
import { StatsApiLinescore } from '../../../shared/interfaces/apiInterfaces';
import { advanceLinescore } from '../../../shared/utils/gameUtils';
import BatterPovToggle from '../../selectors/BatterPovToggle';
import CountToggle from '../../selectors/CountToggle';
import HandednessToggle from '../../selectors/HandednessToggle';
import DelayPicker from '../DelayPicker';
import ScoreBug from '../ScoreBug';

interface LiveInfoAndTogglesProps {
  linescore: StatsApiLinescore;
  delay: number;
  setDelay: (value: number) => void;
  batterPov: boolean;
  setBatterPov: (value: boolean) => void;
  handednessFilter: boolean;
  setHandednessFilter: (value: boolean) => void;
  countFilter: boolean;
  setCountFilter: (value: boolean) => void;
}

export default function LiveInfoAndToggles(props: LiveInfoAndTogglesProps) {

  const linescore = advanceLinescore(props.linescore);

  return (
    <VStack
      justify='space-between'
      width='190px'
      border='1px solid lightgrey'
      borderRadius='md'
    >
      <ScoreBug linescore={linescore} />
      <DelayPicker delay={props.delay} setDelay={props.setDelay} />
      <Box>Batter: {linescore.batter_name}</Box>
      <Box>Pitcher: {linescore.pitcher_name}</Box>
      <BatterPovToggle batter={props.batterPov} setBatter={props.setBatterPov} />
      <HandednessToggle handednessFilter={props.handednessFilter} setHandednessFilter={props.setHandednessFilter} />
      <CountToggle countFilter={props.countFilter} setCountFilter={props.setCountFilter} />
    </VStack>
  );
}
