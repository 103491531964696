import _ from 'lodash';
import { saveFrontendLog } from '../api/backendApi';
import { GumboUpdate, InitialGameData } from '../shared/interfaces/apiInterfaces';
import { Gumbo } from '../shared/interfaces/gumboInterfaces';
import { applyPatchPure } from '../shared/utils/gumbo/gumboPatcher';
import { removeGumboPure } from '../shared/utils/gumbo/gumboUtils';

export interface LiveGameData {
  gumbo?: Gumbo;
  updates: GumboUpdate[];
}

export const initialLiveGameData = () => {
  return {
    gumbo: undefined,
    updates: []
  };
}

export type LiveGameAction = InitialDataAction | UpdateDataAction;

export interface InitialDataAction {
  type: 'initial_data';
  data: InitialGameData;
}

export interface UpdateDataAction {
  type: 'game_update';
  updates: GumboUpdate[];
}

export function liveGameDataReducer(
  state: LiveGameData,
  action: LiveGameAction
): LiveGameData {
  // TODO: What if the gumbo patch fails to apply?
  // TODO: make sure the logic of stripping gumbo from the updates is correct.
  switch (action.type) {
    case 'initial_data': {
      if (state.updates.length === 0) {
        // No updates yet, so just use the initial data.
        return {
          gumbo: action.data.gumbo,
          updates: action.data.updates
        };
      } else {
        // We already got some updates.
        const merged = mergeUpdatesPure(action.data.updates, state.updates);
        const gumbo = updateGumboPure(action.data.gumbo, merged);
        const updates = merged.map(removeGumboPure);
        if (gumbo == null) {
          // Patching gumbo failed, so we'll just use the initial data gumbo.
          // Don't strip gumbo from the updates in case we need them later.
          return {
            gumbo: state.gumbo,
            updates: merged
          };
        } else {
          // Patching was successful.
          return {
            gumbo,
            updates
          };
        }
      }
    }
    case 'game_update': {
      const merged = mergeUpdatesPure(action.updates, state.updates);
      if (state.gumbo == null) {
        // No initial data yet, so use the updates and any gumbo that we find in them.
        const maybeGumbo = getUpdatesGumbo(action.updates);
        return {
          gumbo: maybeGumbo,
          updates: merged
        };
      } else {
        const gumbo = updateGumboPure(state.gumbo, merged);
        const updates = merged.map(removeGumboPure);
        if (gumbo == null) {
          // Patching gumbo failed, so we'll just use the existing gumbo.
          // Don't strip gumbo from the updates in case we need them later.
          return {
            gumbo: state.gumbo,
            updates: merged
          };
        } else {
          // Patching was successful.
          return {
            gumbo,
            updates
          };
        }
      }
    }
    default: {
      console.log(`ERROR: Unknown action type: ${action}`);
      return state;
    }
  }
}

function mergeUpdatesPure(
  newUpdates: GumboUpdate[],
  existingUpdates: GumboUpdate[]
): GumboUpdate[] {
  const copy = _.cloneDeep(existingUpdates);
  for (const update of newUpdates) {
    copy[update.index] = update;
  }
  return copy;
}

function updateGumboPure(
  gumbo: Gumbo,
  updates: GumboUpdate[]
): Gumbo | undefined {
  let updated: Gumbo | undefined = undefined;

  const index = _.findIndex(updates, (update) => {
    return update?.timestamp === gumbo.metaData.timeStamp;
  });

  let missingUpdate = false;
  let patchFailed = false;

  for (let i = index + 1; i < updates.length; i++) {
    const update = updates[i];
    if (update == null) {
      missingUpdate = true;
      continue;
    }
    switch (update.type) {
      case 'patch': {
        if (!patchFailed && !missingUpdate) {
          const maybePatched = applyPatchPure(updated || gumbo, update.patch!);
          if (maybePatched != null) {
            updated = maybePatched;
          } else {
            // TODO: this sometimes keeps getting printed even though we should(?) have gotten a full gumbo later in the list of updates.
            console.log(`ERROR: Patching failed for update ${update.index}`);
            patchFailed = true;
            const logData = {
              success: false,
              type: 'gumbo_patch_failed',
              update,
              previousGumbo: gumbo
            };
            saveFrontendLog(logData);
          }
        }
        break;
      }
      case 'full_gumbo': {
        if (update.gumbo != null) {
          updated = update.gumbo;
          missingUpdate = false;
          patchFailed = false;
        } else {
          // Full gumbo was stripped. We can ignore this.
        }
        break;
      }
      case 'empty_patch': {
        break;
      }
      case 'patch_failed': {
        patchFailed = true;
        break;
      }
      case 'previous_patch_failed': {
        patchFailed = true;
        break;
      }
      case 'empty_update': {
        break;
      }
      default: {
        console.log(`ERROR: Unknown update type: ${update.type}`);
      }
    }
  }

  return updated;
}

function getUpdatesGumbo(updates: GumboUpdate[]): Gumbo | undefined {
  for (let i = updates.length - 1; i >= 0; i--) {
    const update = updates[i];
    if (update?.type === 'full_gumbo' && update?.gumbo != null) {
      return update.gumbo;
    }
  }
}
