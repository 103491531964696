import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import _ from 'lodash';
import { Gumbo } from '../../shared/interfaces/gumboInterfaces';
import { getPitcherEvents } from '../../shared/utils/gumbo/gumboUtils';
import { StatcastPitchAverages, StatcastPitcherAverages } from '../../shared/interfaces/apiInterfaces';
import { getStatcastPitcherAverages } from '../../api/statcastApi';
import { PitchEvent } from '../../shared/interfaces/gameInterfaces';
import RelativePitcherFatigue from '../charts/plotly/RelativePitcherFatigue';
import { pitchToColor } from '../../shared/constants/statcast';
import FatigueLegend from '../charts/legends/FatigueLegend';

interface PitcherFatigueProps {
  gumbo: Gumbo;
  pitcherId: number;
}

export default function PitcherFatigue(props: PitcherFatigueProps) {

  const pitcherEvents = getPitcherEvents(props.pitcherId, props.gumbo);

  const [pitchAverages, setPitchAverages] = useState<StatcastPitcherAverages | null>(null);

  function updatePitchAverages(pitcherId: number) {
    getStatcastPitcherAverages(pitcherId).then(setPitchAverages);
  }

  useEffect(() => {
    updatePitchAverages(props.pitcherId);
  }, [props.pitcherId]);

  const x = _.range(1, pitcherEvents.length + 1);
  const yVelo = calcRelativePitchVelocity(pitcherEvents, pitchAverages?.averages || []);
  const ySpinRate = calcRelativePitchSpinRate(pitcherEvents, pitchAverages?.averages || []);
  const pitchTypes = getPitchTypes(pitcherEvents);
  const color = pitchTypes.map(pitchToColor);

  return (
    <Tabs
      size='sm'
      width='704px'
      height='605px'
      border='1px solid lightgrey'
      borderRadius='md'
    >
      <TabList>
        <Tab>Velo Fatigue</Tab>
        <Tab>Spin Rate Fatigue</Tab>
      </TabList>
      <TabPanels>
        <TabPanel padding={0}>
          <FatigueLegend averages={pitchAverages?.averages || []} isVelo={true} />
          <RelativePitcherFatigue
            x={x}
            y={yVelo}
            color={color}
            normal={pitchAverages?.velo_loess_y}
          />
        </TabPanel>
        <TabPanel padding={0}>
        <FatigueLegend averages={pitchAverages?.averages || []} isVelo={false} />
          <RelativePitcherFatigue
            x={x}
            y={ySpinRate}
            color={color}
            normal={pitchAverages?.spin_rate_loess_y}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

function getPitchTypes(pitcherEvents: PitchEvent[]): string[] {
  return pitcherEvents.map((event) => event.pitchType);
}

function getPitchColors(pitcherEvents: PitchEvent[]): string[] {
  return pitcherEvents.map((event) => pitchToColor(event.pitchType));
}

function getPitchPlateCoords(pitcherEvents: PitchEvent[]): { x: number[]; y: number[]; } {
  const x: number[] = [];
  const y: number[] = [];
  for (const event of pitcherEvents) {
    x.push(event.event.pitchData.coordinates.pX);
    y.push(event.event.pitchData.coordinates.pZ);
  }
  return { x, y };
}

function calcRelativePitchVelocity(
  pitcherEvents: PitchEvent[],
  pitchAverages: StatcastPitchAverages[]
): number[] {
  const averagesMap = toVeloAveragesMap(pitchAverages);
  return pitcherEvents.map((event) =>
    event.pitchType in averagesMap ? event.event.pitchData.startSpeed / averagesMap[event.pitchType] : 1
  );
}

function calcRelativePitchSpinRate(
  pitcherEvents: PitchEvent[],
  pitchAverages: StatcastPitchAverages[]
): number[] {
  const averagesMap = toSpinRateAveragesMap(pitchAverages);
  return pitcherEvents.map((event) =>
    event.pitchType in averagesMap ? event.event.pitchData.breaks.spinRate / averagesMap[event.pitchType] : 1
  );
}

function toVeloAveragesMap(pitchAverages: StatcastPitchAverages[]): { [key: string]: number } {
  const averagesMap: { [key: string]: number } = {};
  for (const pitch of pitchAverages) {
    averagesMap[pitch.pitch_type] = pitch.avg_release_speed;
  }
  return averagesMap;
}

function toSpinRateAveragesMap(pitchAverages: StatcastPitchAverages[]): { [key: string]: number } {
  const averagesMap: { [key: string]: number } = {};
  for (const pitch of pitchAverages) {
    averagesMap[pitch.pitch_type] = pitch.avg_release_spin_rate;
  }
  return averagesMap;
}
