import Plot from 'react-plotly.js';
import { pitchToColor, pitchToName } from '../../../shared/constants/statcast';
import { calcPitchMix } from '../../../shared/utils/calcUtils';
import { orderPitches } from '../../../shared/utils/filterUtils';

function PitchMix(props: PitchMixProps) {

  const pitchCounts = calcPitchMix(props.pitch_type);
  const orderedPitches = orderPitches(Array.from(pitchCounts.keys()));

  const labels = orderedPitches.map(pitchToName);
  const values = orderedPitches.map(p => pitchCounts.get(p) || 0);
  const colors = orderedPitches.map(pitchToColor);

  return (
    <Plot
      data={[
        {
          labels,
          values,
          type: 'pie',
          marker: { colors },
          sort: false,
          direction: 'clockwise',
        },
      ]}
      layout={ {
        showlegend: false,
        autosize: true,
        margin: {
          l: 4,
          r: 4,
          t: 4,
          b: 4
        }
      } }
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      config={{ staticPlot: true }}
    />
  );
}

interface PitchMixProps {
  pitch_type: string[];
}

export default PitchMix;
