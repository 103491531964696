import BooleanCheckbox from './base/BooleanCheckbox';

interface CountToggleProps {
  countFilter: boolean;
  setCountFilter: (value: boolean) => void;
}

export default function CountToggle(props: CountToggleProps) {
  return (
    <BooleanCheckbox
      value={props.countFilter}
      setValue={props.setCountFilter}
      text='Count Filter'
    />
  );
}
