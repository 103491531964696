import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Spacer, StackDivider, VStack } from '@chakra-ui/react';
import { getTeamAbbr } from '../../shared/constants/misc';
import { DelayedLinescore } from '../../shared/interfaces/appGumboInterfaces';
import { CountAndOuts } from '../../shared/interfaces/gameInterfaces';

interface GumboScoreBugProps {
  displayLinescore: DelayedLinescore;
  count: CountAndOuts;
  homeTeamId: number;
  awayTeamId: number;
}

export default function GumboScoreBug(props: GumboScoreBugProps) {

  const linescore = props.displayLinescore.linescore;

  return (
    <Flex w='170px' border='1px solid' borderColor='gray.500'>
      <Box w='68px' borderRight='1px solid' borderColor='gray.200'>
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          align='stretch'
          spacing={0}
        >
          <Box>
            <Flex margin='0.35rem'>
              <Center>{getTeamAbbr(props.awayTeamId)}</Center>
              <Spacer />
              <Box>{linescore.teams.away.runs}</Box>
            </Flex>
          </Box>
          <Box>
            <Flex margin='0.35rem'>
              <Center>{getTeamAbbr(props.homeTeamId)}</Center>
              <Spacer />
              <Box>{linescore.teams.home.runs}</Box>
            </Flex>
          </Box>
        </VStack>
      </Box>
      <Box marginLeft='5px'>
        <VStack align='stretch'>
          <Flex>
            <Innning inning={linescore.currentInning} isTopInning={linescore.isTopInning || false} />
            <Spacer />
            <Center>
              <Bases
                on1={linescore.offense.first != null}
                on2={linescore.offense.second != null}
                on3={linescore.offense.third != null}
              />
            </Center>
          </Flex>
          <Flex>
            <Outs outs={props.count.outs} />
            <Spacer />
            <Box lineHeight='90%' marginRight='10px'>{`${props.count.balls}-${props.count.strikes}`}</Box>
          </Flex>
        </VStack>
      </Box>
    </Flex>
  );
}

function Outs({ outs } : { outs: number }) {
  const size = '12px';
  return (
    <Flex>
      <Box w={size} h={size} marginRight='3px' borderRadius='50%' background={outs > 0 ?
      '#fee600' : '#929392'} />
      <Box w={size} h={size} borderRadius='50%' background={outs > 1 ?
      '#fee600' : '#929392'} />
    </Flex>
  );
}

function Innning2({ inning, isTopInning } : { inning: number, isTopInning: boolean }) {
  return (
    <VStack align='stretch'>
      <Box marginTop='0rem' marginBottom='-0.5rem'>
        <TriangleUpIcon color={isTopInning ? '#fee600' : '#929392'} />
      </Box>
      <Box lineHeight='90%' margin='0'>{inning}</Box>
      <Box margin='0'>
        <TriangleDownIcon margin='0' color={isTopInning ? '#929392' : '#fee600'} />
      </Box>
    </VStack>
  );
}

function Innning({ inning, isTopInning } : { inning: number, isTopInning: boolean }) {
  const icon = isTopInning ? <TriangleUpIcon /> : <TriangleDownIcon />;
  return (
    <Flex alignItems={'center'} marginRight='5px'>
      {icon}
      <Box>{inning}</Box>
    </Flex>
  );
}

function Bases({ on1, on2, on3 } : { on1: boolean, on2: boolean, on3: boolean }) {
  const size = 15;
  return (
    <Box position='relative' h='42px' w='56px'>
      <Box position='absolute' left='12px' top='23px'>
        <Diamond size={size} on={on3} />
      </Box>
      <Box position='absolute' left='27px' top='8px'>
        <Diamond size={size} on={on2} />
      </Box>
      <Box position='absolute' left='42px' top='23px'>
        <Diamond size={size} on={on1} />
      </Box>
    </Box>
  );
}

function Diamond({ size, on } : { size: number, on: boolean }) {
  const background = on ? '#fee600' : '#929392';
  return (
    <Box w={size} h={size} background={background}
      transform='rotate(-45deg)' transformOrigin='0 100%'>
    </Box>
  );
}
