import { StatcastRawBatterData } from '../interfaces/apiInterfaces';
import { didBatterMakeContact } from './filterUtils';

export function groupByContact(data: StatcastRawBatterData): [StatcastRawBatterData, StatcastRawBatterData] {
  const contact: StatcastRawBatterData = [];
  const miss: StatcastRawBatterData = [];
  for (const record of data) {
    if (didBatterMakeContact(record)) {
      contact.push(record);
    } else {
      miss.push(record);
    }
  }
  return [contact, miss];
}

export function getPlatexPov(data: StatcastRawBatterData, batterPov: boolean): number[] {
  return batterPov ? data.map(x => x.plate_x) : data.map(x => x.plate_x * -1);
}
