import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import { whiteStrikeZone } from '../../../shared/utils/plotUtils';
import { dontDrawAxisOptions, zeroMargins } from '../../../shared/constants/plotly';

interface PlatePitchHeatmapProps {
  plate_x: number[];
  plate_z: number[];
  batterPov: boolean;
  title?: string;
  showScale?: boolean;
}

export default function PlatePitchHeatmap(props: PlatePitchHeatmapProps) {

  // Should we adjust the bins number/size to the amount of data?

  const x = props.batterPov ? props.plate_x : props.plate_x.map(x => x * -1);

  let data: Partial<Plotly.PlotData> = {
    x,
    y: props.plate_z,
    type: 'histogram2d',
    colorscale: 'Bluered',
    nbinsx: 60,
    xbinsy: 60,
  } as Partial<Plotly.PlotData>;

  if (!props.showScale) {
    data.showscale = false;
  }

  const shapes = [whiteStrikeZone];

  const yaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    range: [0, 5],
  } as Partial<Plotly.LayoutAxis>;
  const xaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    range: [-2, 2],
  } as Partial<Plotly.LayoutAxis>;

  let layout: Partial<Plotly.Layout> = {
    yaxis,
    xaxis,
    width: 320,
    height: 400,
    margin: zeroMargins,
    shapes
  };

  if (props.title != null) {
    layout.title = props.title;
  }

  return (
    <Plot
      data={[data]}
      layout={layout}
      config={{ staticPlot: true }}
    />
  );
}
