import { TeamInfo } from '../interfaces/apiInterfaces';

/*
AL west = 200
AL east = 201
AL cent = 202
NL west = 203
NL east = 204
NL cent = 205
*/

export const teamList: TeamInfo[] = [
  {
    id: 133,
    name: 'Oakland Athletics',
    season: 2022,
    venueId: 10,
    venueName: 'Oakland Coliseum',
    abbreviation: 'OAK',
    teamName: 'Athletics',
    locationName: 'Oakland',
    shortName: 'Oakland',
    franchiseName: 'Oakland',
    clubName: 'Athletics',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 200,
    divisionName: 'American League West',
  },
  {
    id: 134,
    name: 'Pittsburgh Pirates',
    season: 2022,
    venueId: 31,
    venueName: 'PNC Park',
    abbreviation: 'PIT',
    teamName: 'Pirates',
    locationName: 'Pittsburgh',
    shortName: 'Pittsburgh',
    franchiseName: 'Pittsburgh',
    clubName: 'Pirates',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 205,
    divisionName: 'National League Central',
  },
  {
    id: 135,
    name: 'San Diego Padres',
    season: 2022,
    venueId: 2680,
    venueName: 'Petco Park',
    abbreviation: 'SD',
    teamName: 'Padres',
    locationName: 'San Diego',
    shortName: 'San Diego',
    franchiseName: 'San Diego',
    clubName: 'Padres',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 203,
    divisionName: 'National League West',
  },
  {
    id: 136,
    name: 'Seattle Mariners',
    season: 2022,
    venueId: 680,
    venueName: 'T-Mobile Park',
    abbreviation: 'SEA',
    teamName: 'Mariners',
    locationName: 'Seattle',
    shortName: 'Seattle',
    franchiseName: 'Seattle',
    clubName: 'Mariners',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 200,
    divisionName: 'American League West',
  },
  {
    id: 137,
    name: 'San Francisco Giants',
    season: 2022,
    venueId: 2395,
    venueName: 'Oracle Park',
    abbreviation: 'SF',
    teamName: 'Giants',
    locationName: 'San Francisco',
    shortName: 'San Francisco',
    franchiseName: 'San Francisco',
    clubName: 'Giants',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 203,
    divisionName: 'National League West',
  },
  {
    id: 138,
    name: 'St. Louis Cardinals',
    season: 2022,
    venueId: 2889,
    venueName: 'Busch Stadium',
    abbreviation: 'STL',
    teamName: 'Cardinals',
    locationName: 'St. Louis',
    shortName: 'St. Louis',
    franchiseName: 'St. Louis',
    clubName: 'Cardinals',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 205,
    divisionName: 'National League Central',
  },
  {
    id: 139,
    name: 'Tampa Bay Rays',
    season: 2022,
    venueId: 12,
    venueName: 'Tropicana Field',
    abbreviation: 'TB',
    teamName: 'Rays',
    locationName: 'St. Petersburg',
    shortName: 'Tampa Bay',
    franchiseName: 'Tampa Bay',
    clubName: 'Rays',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 201,
    divisionName: 'American League East',
  },
  {
    id: 140,
    name: 'Texas Rangers',
    season: 2022,
    venueId: 5325,
    venueName: 'Globe Life Field',
    abbreviation: 'TEX',
    teamName: 'Rangers',
    locationName: 'Arlington',
    shortName: 'Texas',
    franchiseName: 'Texas',
    clubName: 'Rangers',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 200,
    divisionName: 'American League West',
  },
  {
    id: 141,
    name: 'Toronto Blue Jays',
    season: 2022,
    venueId: 14,
    venueName: 'Rogers Centre',
    abbreviation: 'TOR',
    teamName: 'Blue Jays',
    locationName: 'Toronto',
    shortName: 'Toronto',
    franchiseName: 'Toronto',
    clubName: 'Blue Jays',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 201,
    divisionName: 'American League East',
  },
  {
    id: 142,
    name: 'Minnesota Twins',
    season: 2022,
    venueId: 3312,
    venueName: 'Target Field',
    abbreviation: 'MIN',
    teamName: 'Twins',
    locationName: 'Minneapolis',
    shortName: 'Minnesota',
    franchiseName: 'Minnesota',
    clubName: 'Twins',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 202,
    divisionName: 'American League Central',
  },
  {
    id: 143,
    name: 'Philadelphia Phillies',
    season: 2022,
    venueId: 2681,
    venueName: 'Citizens Bank Park',
    abbreviation: 'PHI',
    teamName: 'Phillies',
    locationName: 'Philadelphia',
    shortName: 'Philadelphia',
    franchiseName: 'Philadelphia',
    clubName: 'Phillies',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 204,
    divisionName: 'National League East',
  },
  {
    id: 144,
    name: 'Atlanta Braves',
    season: 2022,
    venueId: 4705,
    venueName: 'Truist Park',
    abbreviation: 'ATL',
    teamName: 'Braves',
    locationName: 'Atlanta',
    shortName: 'Atlanta',
    franchiseName: 'Atlanta',
    clubName: 'Braves',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 204,
    divisionName: 'National League East',
  },
  {
    id: 145,
    name: 'Chicago White Sox',
    season: 2022,
    venueId: 4,
    venueName: 'Guaranteed Rate Field',
    abbreviation: 'CWS',
    teamName: 'White Sox',
    locationName: 'Chicago',
    shortName: 'Chi White Sox',
    franchiseName: 'Chicago',
    clubName: 'White Sox',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 202,
    divisionName: 'American League Central',
  },
  {
    id: 146,
    name: 'Miami Marlins',
    season: 2022,
    venueId: 4169,
    venueName: 'loanDepot park',
    abbreviation: 'MIA',
    teamName: 'Marlins',
    locationName: 'Miami',
    shortName: 'Miami',
    franchiseName: 'Miami',
    clubName: 'Marlins',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 204,
    divisionName: 'National League East',
  },
  {
    id: 147,
    name: 'New York Yankees',
    season: 2022,
    venueId: 3313,
    venueName: 'Yankee Stadium',
    abbreviation: 'NYY',
    teamName: 'Yankees',
    locationName: 'Bronx',
    shortName: 'NY Yankees',
    franchiseName: 'New York',
    clubName: 'Yankees',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 201,
    divisionName: 'American League East',
  },
  {
    id: 158,
    name: 'Milwaukee Brewers',
    season: 2022,
    venueId: 32,
    venueName: 'American Family Field',
    abbreviation: 'MIL',
    teamName: 'Brewers',
    locationName: 'Milwaukee',
    shortName: 'Milwaukee',
    franchiseName: 'Milwaukee',
    clubName: 'Brewers',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 205,
    divisionName: 'National League Central',
  },
  {
    id: 108,
    name: 'Los Angeles Angels',
    season: 2022,
    venueId: 1,
    venueName: 'Angel Stadium',
    abbreviation: 'LAA',
    teamName: 'Angels',
    locationName: 'Anaheim',
    shortName: 'LA Angels',
    franchiseName: 'Los Angeles',
    clubName: 'Angels',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 200,
    divisionName: 'American League West',
  },
  {
    id: 109,
    name: 'Arizona Diamondbacks',
    season: 2022,
    venueId: 15,
    venueName: 'Chase Field',
    abbreviation: 'AZ',
    teamName: 'D-backs',
    locationName: 'Phoenix',
    shortName: 'Arizona',
    franchiseName: 'Arizona',
    clubName: 'Diamondbacks',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 203,
    divisionName: 'National League West',
  },
  {
    id: 110,
    name: 'Baltimore Orioles',
    season: 2022,
    venueId: 2,
    venueName: 'Oriole Park at Camden Yards',
    abbreviation: 'BAL',
    teamName: 'Orioles',
    locationName: 'Baltimore',
    shortName: 'Baltimore',
    franchiseName: 'Baltimore',
    clubName: 'Orioles',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 201,
    divisionName: 'American League East',
  },
  {
    id: 111,
    name: 'Boston Red Sox',
    season: 2022,
    venueId: 3,
    venueName: 'Fenway Park',
    abbreviation: 'BOS',
    teamName: 'Red Sox',
    locationName: 'Boston',
    shortName: 'Boston',
    franchiseName: 'Boston',
    clubName: 'Red Sox',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 201,
    divisionName: 'American League East',
  },
  {
    id: 112,
    name: 'Chicago Cubs',
    season: 2022,
    venueId: 17,
    venueName: 'Wrigley Field',
    abbreviation: 'CHC',
    teamName: 'Cubs',
    locationName: 'Chicago',
    shortName: 'Chi Cubs',
    franchiseName: 'Chicago',
    clubName: 'Cubs',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 205,
    divisionName: 'National League Central',
  },
  {
    id: 113,
    name: 'Cincinnati Reds',
    season: 2022,
    venueId: 2602,
    venueName: 'Great American Ball Park',
    abbreviation: 'CIN',
    teamName: 'Reds',
    locationName: 'Cincinnati',
    shortName: 'Cincinnati',
    franchiseName: 'Cincinnati',
    clubName: 'Reds',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 205,
    divisionName: 'National League Central',
  },
  {
    id: 114,
    name: 'Cleveland Guardians',
    season: 2022,
    venueId: 5,
    venueName: 'Progressive Field',
    abbreviation: 'CLE',
    teamName: 'Guardians',
    locationName: 'Cleveland',
    shortName: 'Cleveland',
    franchiseName: 'Cleveland',
    clubName: 'Guardians',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 202,
    divisionName: 'American League Central',
  },
  {
    id: 115,
    name: 'Colorado Rockies',
    season: 2022,
    venueId: 19,
    venueName: 'Coors Field',
    abbreviation: 'COL',
    teamName: 'Rockies',
    locationName: 'Denver',
    shortName: 'Colorado',
    franchiseName: 'Colorado',
    clubName: 'Rockies',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 203,
    divisionName: 'National League West',
  },
  {
    id: 116,
    name: 'Detroit Tigers',
    season: 2022,
    venueId: 2394,
    venueName: 'Comerica Park',
    abbreviation: 'DET',
    teamName: 'Tigers',
    locationName: 'Detroit',
    shortName: 'Detroit',
    franchiseName: 'Detroit',
    clubName: 'Tigers',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 202,
    divisionName: 'American League Central',
  },
  {
    id: 117,
    name: 'Houston Astros',
    season: 2022,
    venueId: 2392,
    venueName: 'Minute Maid Park',
    abbreviation: 'HOU',
    teamName: 'Astros',
    locationName: 'Houston',
    shortName: 'Houston',
    franchiseName: 'Houston',
    clubName: 'Astros',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 200,
    divisionName: 'American League West',
  },
  {
    id: 118,
    name: 'Kansas City Royals',
    season: 2022,
    venueId: 7,
    venueName: 'Kauffman Stadium',
    abbreviation: 'KC',
    teamName: 'Royals',
    locationName: 'Kansas City',
    shortName: 'Kansas City',
    franchiseName: 'Kansas City',
    clubName: 'Royals',
    leagueId: 103,
    leagueName: 'American League',
    divisionId: 202,
    divisionName: 'American League Central',
  },
  {
    id: 119,
    name: 'Los Angeles Dodgers',
    season: 2022,
    venueId: 22,
    venueName: 'Dodger Stadium',
    abbreviation: 'LAD',
    teamName: 'Dodgers',
    locationName: 'Los Angeles',
    shortName: 'LA Dodgers',
    franchiseName: 'Los Angeles',
    clubName: 'Dodgers',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 203,
    divisionName: 'National League West',
  },
  {
    id: 120,
    name: 'Washington Nationals',
    season: 2022,
    venueId: 3309,
    venueName: 'Nationals Park',
    abbreviation: 'WSH',
    teamName: 'Nationals',
    locationName: 'Washington',
    shortName: 'Washington',
    franchiseName: 'Washington',
    clubName: 'Nationals',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 204,
    divisionName: 'National League East',
  },
  {
    id: 121,
    name: 'New York Mets',
    season: 2022,
    venueId: 3289,
    venueName: 'Citi Field',
    abbreviation: 'NYM',
    teamName: 'Mets',
    locationName: 'Flushing',
    shortName: 'NY Mets',
    franchiseName: 'New York',
    clubName: 'Mets',
    leagueId: 104,
    leagueName: 'National League',
    divisionId: 204,
    divisionName: 'National League East',
  }
];

export const teamMap: Map<number, TeamInfo> = new Map(teamList.map((team) => [team['id'], team]));

export const teamTeamNameMap: Map<number, string> = new Map(teamList.map((team) => [team.id, team.teamName]));

export const divisionSet = new Set(teamList.map((team) => [team.divisionId, team.divisionName]));

function groupTeamsByDivison(teams: TeamInfo[]): Map<number, number[]> {
  const teamsByDivsion = new Map<number, number[]>();
  for (const team of teams) {
    teamsByDivsion.set(team.divisionId, (teamsByDivsion.get(team.divisionId) || []).concat(team.id));
  }
  return teamsByDivsion;
}

export const teamsByDivisionId = groupTeamsByDivison(teamList);
