import { StatsApiSchedule } from '../interfaces/apiInterfaces';

export function formatUtcDate(d: Date): string {
  const month = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  const year = d.getUTCFullYear();
  return `${month}/${day}/${year}`;
}

export function formatTime(d: Date): string {
  const minute = d.getMinutes();
  const pad = minute < 10 ? '0' : '';
  let hour = d.getHours();
  let ampm = 'AM';
  if (hour === 0) {
    hour = 12;
  } else if (hour >= 12) {
    ampm = 'PM';
    if (hour > 12) {
      hour -= 12;
    }
  }
  return `${hour}:${pad}${minute} ${ampm}`;
}

export function formatTimeOnly(d: Date): string {
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function formatInning(game: StatsApiSchedule): string | null {
  if (game.inning == null) {
    return null;
  }
  return `${game.is_top_inning ? 'T' : 'B'}${game.inning}`;
}

export function formatInningNumber(value: number): string {
  if (value > 3 && value < 21) {
    return `${value}th`;
  }
  switch (value % 10) {
    case 1: return `${value}st`;
    case 2: return `${value}nd`;
    case 3: return `${value}rd`;
    default: return `${value}th`;
  }
}
