import { GumboUpdate } from '../../interfaces/apiInterfaces';
import { DelayedPlay, DelayedLinescore } from '../../interfaces/appGumboInterfaces';
import { Gumbo } from '../../interfaces/gumboInterfaces';
import { gumboTimestampToDateTime, getMatchupInfoFromPlay, getPlay } from './gumboUtils';

export function getDelayedLinescore(updates: GumboUpdate[], delay: number): DelayedLinescore | undefined {
  const now = new Date();
  return getDelayedLinescorePure(updates, delay, now);
}

export function getDelayedLinescorePure(updates: GumboUpdate[], delay: number, date: Date): DelayedLinescore | undefined {
  const delayedTime = new Date(date.getTime());
  delayedTime.setSeconds(delayedTime.getSeconds() - delay);
  let indeterminate = false;

  for (let i = updates.length - 1; i >= 0; i--) {
    const update = updates[i];
    if (update?.meta?.linescore != null && update?.timestamp != null) {
      const updateTimestamp = gumboTimestampToDateTime(update.timestamp);
      if (updateTimestamp < delayedTime) {
        return {
          linescore: update.meta.linescore,
          indeterminate,
          timestamp: update.timestamp,
        };
      } else {
        indeterminate = false;
      }
    } else if (update?.timestamp != null) {
      const updateTimestamp = gumboTimestampToDateTime(update.timestamp);
      if (updateTimestamp < delayedTime) {
        indeterminate = true;
      }
    }
  }
}

export function getDelayedPlayInfo(gumbo: Gumbo, dp: DelayedPlay) {
  switch (dp.location) {
    case 'before-first-event': {
      return null;
    }
    case 'after-last-event':
    case 'end-of-last-event': {
      // TODO: If the play has ended, should we display the upcoming batter + pitcher?
      const play = getPlay(gumbo, dp.playIndex);
      const event = play.playEvents[dp.eventIndex];
      const matchup = getMatchupInfoFromPlay(play);
      const count = {
        balls: play.count.balls,
        strikes: play.count.strikes,
        outs: play.count.outs
      };
      return {
        play,
        event,
        count,
        matchup
      };
    }
    case 'between-events':
    case 'end-of-event': {
      const play = getPlay(gumbo, dp.nextEventIndexes.playIndex);
      const event = play.playEvents[dp.nextEventIndexes.eventIndex];
      const matchup = getMatchupInfoFromPlay(play);
      // Need to get count from previous event (but outs from the next event).
      const previousEvent = getPlay(gumbo, dp.playIndex).playEvents[dp.eventIndex];
      const count = {
        balls: previousEvent.count.balls,
        strikes: previousEvent.count.strikes,
        outs: event.count.outs
      };
      return {
        play,
        event,
        count,
        matchup
      };
    }
    case 'between-plays': {
      const play = getPlay(gumbo, dp.nextEventIndexes.playIndex);
      const event = play.playEvents[dp.nextEventIndexes.eventIndex];
      const matchup = getMatchupInfoFromPlay(play);
      // Get outs from previous play.
      const outs = getPlay(gumbo, dp.playIndex).count.outs;
      const count = {
        balls: 0,
        strikes: 0,
        outs
      };
      return {
        play,
        event,
        count,
        matchup
      };
    }
    case 'mid-event': {
      const play = getPlay(gumbo, dp.playIndex);
      const event = play.playEvents[dp.eventIndex];
      const matchup = getMatchupInfoFromPlay(play);
      // should get count from previous event (but outs from the next event).
      // but if the previous event was from the previous play, then balls and strikes are 0.
      let balls: number, strikes: number;
      if (dp.previousEventIndexes.playIndex != dp.playIndex) {
        balls = 0;
        strikes = 0;
      } else {
        const previousEvent = play.playEvents[dp.previousEventIndexes.eventIndex];
        balls = previousEvent.count.balls || 0;
        strikes = previousEvent.count.strikes || 0;
      }
      const count = {
        balls,
        strikes,
        outs: event.count.outs
      };
      return {
        play,
        event,
        count,
        matchup
      };
    }
  }
}

export function getDelayedPlayLocation(gumbo: Gumbo, delay: number): DelayedPlay {
  const now = new Date();
  return getDelayedPlayLocationPure(gumbo, delay, now);
}

export function getDelayedPlayLocationPure(gumbo: Gumbo, delay: number, date: Date): DelayedPlay {
  const plays = gumbo.liveData.plays.allPlays;
  const delayedTime = new Date(date.getTime());
  delayedTime.setSeconds(delayedTime.getSeconds() - delay);

  let afterLastEvent = true;

  for (let playIndex = plays.length - 1; playIndex >= 0; playIndex--) {
    const events = plays[playIndex].playEvents;

    for (let eventIndex = events.length - 1; eventIndex >= 0; eventIndex--) {
      const eventStartTime = new Date(events[eventIndex].startTime);
      const eventEndTime = new Date(events[eventIndex].endTime);

      if (delayedTime < eventStartTime) {
        // we're before an event
        // do nothing, continue looping
      } else if (eventStartTime <= delayedTime && delayedTime <= eventEndTime) {
        // we're in the middle of an event
        // the case where we're exactly at the start of an event is handled here
        return {
          location: 'mid-event',
          playIndex,
          eventIndex,
          previousEventIndexes: getPreviousEventIndexes(gumbo, playIndex, eventIndex),
        };
      } else if (eventEndTime < delayedTime) {
        if (afterLastEvent) {
          // we are after the last event
          return {
            location: 'after-last-event',
            playIndex,
            eventIndex,
          };
        } else {
          // we are between events (and possibly between plays)
          const nextEventIndexes = getNextEventIndexes(gumbo, playIndex, eventIndex);
          if (nextEventIndexes.playIndex > playIndex) {
            return {
              location: 'between-plays',
              playIndex,
              eventIndex,
              nextEventIndexes,
            };
          } else {
            return {
              location: 'between-events',
              playIndex,
              eventIndex,
              nextEventIndexes,
            };
          }
        }
      } else if (eventEndTime === delayedTime) {
        // we're exactly at the end of an event
        if (afterLastEvent) {
          return {
            location: 'end-of-last-event',
            playIndex,
            eventIndex,
          };
        } else {
          return {
            location: 'end-of-event',
            playIndex,
            eventIndex,
            nextEventIndexes: getNextEventIndexes(gumbo, playIndex, eventIndex),
          };
        }
      }

      afterLastEvent = false;
    }
  }

  return {
    location: 'before-first-event',
    playIndex: 0,
    eventIndex: 0
  };
}

function getPreviousEventIndexes(gumbo: Gumbo, playIndex: number, eventIndex: number) {
  if (playIndex === 0 && eventIndex === 0) {
    // This is the first event.
    return { playIndex, eventIndex };
  } else if (eventIndex === 0) {
    return {
      playIndex: playIndex - 1,
      eventIndex: gumbo.liveData.plays.allPlays[playIndex - 1].playEvents.length - 1,
    };
  } else {
    return {
      playIndex,
      eventIndex: eventIndex - 1,
    };
  }
}

function getNextEventIndexes(gumbo: Gumbo, playIndex: number, eventIndex: number) {
  if (playIndex === gumbo.liveData.plays.allPlays.length - 1 && eventIndex === gumbo.liveData.plays.allPlays[playIndex].playEvents.length - 1) {
    // This is the last event.
    return { playIndex, eventIndex };
  } else if (eventIndex === gumbo.liveData.plays.allPlays[playIndex].playEvents.length - 1) {
    return {
      playIndex: playIndex + 1,
      eventIndex: 0,
    };
  } else {
    return {
      playIndex,
      eventIndex: eventIndex + 1,
    };
  }
}
