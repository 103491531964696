import { useEffect, useState } from 'react';
import { HStack } from '@chakra-ui/react';
import { Gumbo } from '../../shared/interfaces/gumboInterfaces';
import { StatcastRawBatterData, StatcastRawPitcherData } from '../../shared/interfaces/apiInterfaces';
import { getBatterDataRecords, getPitcherDataRecords } from '../../api/statcastApi';
import GameReviewData from './GameReviewData';
import GumboJsonViewer from './GumboJsonViewer';
import PitcherFatigue from './PitcherFatigue';

interface GameReviewComponentProps {
  gumbo: Gumbo;
  playIndex: number;
  setPlayIndex: (index: number) => void;
  eventIndex: number;
  setEventIndex: (index: number) => void;
}

export default function GameReviewComponent(props: GameReviewComponentProps) {

  const play = props.gumbo.liveData.plays.allPlays[props.playIndex];
  const event = play.playEvents[props.eventIndex];

  const pitcher = play.matchup.pitcher.id;
  const batter = play.matchup.batter.id;

  const [batterPov, setBatterPov] = useState(true);
  const [handednessFilter, setHandednessFilter] = useState(false);
  const [countFilter, setCountFilter] = useState(false);

  const [pitcherData, setPitcherData] = useState<StatcastRawPitcherData | null>(null);
  const [batterData, setBatterData] = useState<StatcastRawBatterData | null>(null);

  useEffect(() => {
    if (pitcher != null) {
      getPitcherDataRecords(pitcher).then(setPitcherData);
    }
  }, [pitcher]);

  useEffect(() => {
    if (batter != null) {
      getBatterDataRecords(batter).then(setBatterData);
    }
  }, [batter]);

  return (
    <HStack>
      <GameReviewData
        gumbo={props.gumbo}
        playIndex={props.playIndex}
        setPlayIndex={props.setPlayIndex}
        eventIndex={props.eventIndex}
        setEventIndex={props.setEventIndex}
        batterPov={batterPov}
        setBatterPov={setBatterPov}
        handednessFilter={handednessFilter}
        setHandednessFilter={setHandednessFilter}
        countFilter={countFilter}
        setCountFilter={setCountFilter}
        pitcherData={pitcherData || []}
        batterData={batterData || []}
      />
      <PitcherFatigue
        gumbo={props.gumbo}
        pitcherId={pitcher}
      />
      <GumboJsonViewer
        gumbo={props.gumbo}
        playIndex={props.playIndex}
        eventIndex={props.eventIndex}
      />
    </HStack>
  );
}
