import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import LiveGameDataFetcher from '../components/live/LiveGameDataFetcher';
import useWindowSize from '../hooks/useWindowSize';
import WindowSizeContext from '../context/WindowSizeContext';

export default function LiveGame() {
  const windowSize = useWindowSize();

  const gamePk = parseInt(useParams().gamePk || '');

  // TODO: we should do some sort of refresh/check to see if the game has started after not being live for a certain amount of time.

  const [isNotLive, setIsNotLive] = useState(false);

  const setNotLive = () => setIsNotLive(true);

  if (isNotLive) {
    return (
      <Box>
        <Box>Game is not live</Box>
      </Box>
    );
  }

  return (
    <WindowSizeContext.Provider value={windowSize}>
      <Box>
        <LiveGameDataFetcher gamePk={gamePk} gameNotLive={setNotLive} />
      </Box>
    </WindowSizeContext.Provider>
  );
}
