import { extendTheme } from '@chakra-ui/react';
import { pitchColorTheme } from './shared/utils/chakraUtils';

const theme = extendTheme({
  colors: {
    pitch: {
      ...pitchColorTheme
    },
  },
});

export default theme;
