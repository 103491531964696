import { HStack } from '@chakra-ui/react';
import { StatcastPitchAverages } from '../../../shared/interfaces/apiInterfaces';
import FatigueLoessLegend from './FatigueLoessLegend';
import FatiguePitchMeanLegend from './FatiguePitchMeanLegend';

interface FatigueLegendProps {
  averages: StatcastPitchAverages[];
  isVelo: boolean;
}

export default function FatigueLegend(props: FatigueLegendProps) {

  return (
    <HStack
      alignItems='flex-end'
      marginLeft='75px'
    >
      <FatiguePitchMeanLegend averages={props.averages} isVelo={props.isVelo} />
      <FatigueLoessLegend />
    </HStack>
  );
}
