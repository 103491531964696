import React from 'react';
import { Select } from '@chakra-ui/react';

interface PlayerDropdownProps {
  players: [string, number][];
  value: number;
  onChange: (value: number) => void;
}

export default function PlayerDropdown(props: PlayerDropdownProps) {

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(Number(event.target.value));
  }

  return (
    <Select placeholder='Select player' value={props.value} onChange={handleChange}>
      {props.players.map(([playerName, playerId]) => {
        return <option key={playerId} value={playerId}>{playerName}</option>;
      })}
    </Select>
  );
}
