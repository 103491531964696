import { contactDescriptions, noContactDescriptions, pitchOrder, swingDescriptions } from '../constants/statcast';
import { StatcastRawBatterData, StatcastRawBatterDataRecord, StatcastRawPitcherData } from '../interfaces/apiInterfaces';
import { Count } from '../interfaces/gameInterfaces';

export function filterStatcastByExactCount(data: StatcastRawPitcherData, balls: number, strikes: number): StatcastRawPitcherData {
  return data.filter(x => x.balls === balls && x.strikes === strikes);
}

export function filterPitcherDataByExactCount(data: StatcastRawPitcherData, count?: Count): StatcastRawPitcherData {
  return count != null ? data.filter(x => x.balls === count.balls && x.strikes === count.strikes) : data;
}

export function filterBatterDataByExactCount(data: StatcastRawBatterData, count?: Count): StatcastRawBatterData {
  return count != null ? data.filter(x => x.balls === count.balls && x.strikes === count.strikes) : data;
}

export function filterBatterDidSwing(data: StatcastRawBatterData): StatcastRawBatterData {
  return data.filter(didBatterSwing);
}

export function didBatterSwing(record: StatcastRawBatterDataRecord): boolean {
  return swingDescriptions.includes(record.description);
}

export function didBatterMakeContact(record: StatcastRawBatterDataRecord): boolean {
  return contactDescriptions.includes(record.description);
}

export function filterBatterDidSwingAndMiss(data: StatcastRawBatterData): StatcastRawBatterData {
  return data.filter(x => noContactDescriptions.includes(x.description));
}

export function filterVsBatterHandedness(data: StatcastRawPitcherData, handedness?: 'L' | 'R' | any) {
  if (handedness === 'L') {
    return data.filter(x => x.stand === 'L');
  } else if (handedness === 'R') {
    return data.filter(x => x.stand === 'R');
  } else {
    return data;
  }
}

export function filterVsPitcherHandedness(data: StatcastRawBatterData, handedness?: 'L' | 'R' | any) {
  if (handedness === 'L') {
    return data.filter(x => x.p_throws === 'L');
  } else if (handedness === 'R') {
    return data.filter(x => x.p_throws === 'R');
  } else {
    return data;
  }
}


// TODO: Optimize pitch ordering functions.

export function orderPitches(unordered: string[]): string[] {
  return pitchOrder.filter(p => unordered.includes(p));
}

export function orderItemsWithPitches<T>(unordered: T[], getPitch: (t: T) => string): T[] {
  const ordered: T[] = [];
  for (const pitch of pitchOrder) {
    for (const x of unordered) {
      if (getPitch(x) === pitch) {
        ordered.push(x);
        break;
      }
    }
  }
  return ordered;
}
