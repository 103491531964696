import { PreparedPlayerStats, StatcastRawBatterData, StatcastRawPitcherData } from '../interfaces/apiInterfaces';

export class PlayerStatsStore {

  pitchingStatcast: PitcherStatcast = {};
  battingStatcast: BatterStatcast = {};

  pitchingStats: PlayerStats = {};
  battingStats: PlayerStats = {};

  // TODO: handle more than just 2023 statcast records
  constructor(raw: PreparedPlayerStats) {
    for (const playerId in raw) {
      const stats = raw[playerId];
      const battingRecords = stats?.statcast?.batting?.records?.[2023];
      const pitchingRecords = stats?.statcast?.pitching?.records?.[2023];
      this.pitchingStatcast[playerId] = pitchingRecords;
      this.battingStatcast[playerId] = battingRecords;
    }
  }

  getPitchingStatcast(playerId: number): StatcastRawPitcherData {
    return this.pitchingStatcast[playerId];
  }

  getBattingStatcast(playerId: number): StatcastRawBatterData {
    return this.battingStatcast[playerId];
  }

  getBattingStat(playerId: number, stat: string, timeframe: string): any {
    return this.battingStats[playerId]?.[stat]?.[timeframe];
  }

  getPitchingStat(playerId: number, stat: string, timeframe: string): any {
    return this.pitchingStats[playerId]?.[stat]?.[timeframe];
  }

}

interface PlayerStats {
  [playerId: number]: {
    [stat: string]: {
      [timeframe: string]: any;
    }
  }
}

interface PitcherStatcast {
  [playerId: number]: StatcastRawPitcherData;
}

interface BatterStatcast {
  [playerId: number]: StatcastRawBatterData;
}
