import { Box, Center, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { ExtendedStatsApiSchedule, ParsedGameState } from '../../../shared/interfaces/apiInterfaces';
import GameScoreboard from './GameScoreboard';

interface ScoreboardProps {
  schedule: ExtendedStatsApiSchedule[];
}

export default function Scoreboard(props: ScoreboardProps) {

  const live = sortGames(props.schedule, 'Live');
  const delayedLive = sortGames(props.schedule, 'DelayedLive');
  const delayed = sortGames(props.schedule, ['Delayed', 'DelayedPreview']);
  const preview = sortGames(props.schedule, 'Preview');
  const final = sortGames(props.schedule, 'Final');
  const postponed = sortGames(props.schedule, 'Postponed');
  const cancelled = sortGames(props.schedule, 'Cancelled');
  const unknown = sortGames(props.schedule, 'Unknown');

  const sorted = _.concat(live, delayedLive, delayed, preview, final, postponed, cancelled, unknown);

  return (
    <Box w='100%' marginTop={3}>
      <Center>
        {props.schedule.length === 0
        ? 'No games today'
        : (<VStack>
            {sorted.map(game =>
              <GameScoreboard key={game.game_pk} game={game} />
            )}
          </VStack>)
        }
      </Center>
    </Box>
  );
}

function sortGames(games: ExtendedStatsApiSchedule[], states: ParsedGameState[] | ParsedGameState) {
  const statesArray = Array.isArray(states) ? states : [states];
  const filtered = games.filter(g => statesArray.includes(g.parsed_game_state));
  return _.sortBy(filtered, ['game_date', 'home_team_name']);
}
