import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAOHaA-MQ5Qm4b3WxQ3ra7jEWrpZsU1tUo',
  authDomain: 'thefifthseam-testing.firebaseapp.com',
  projectId: 'thefifthseam-testing',
  storageBucket: 'thefifthseam-testing.appspot.com',
  messagingSenderId: '905269519877',
  appId: '1:905269519877:web:f96649a9d2e26d0bfa1e6c'
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
