import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Gumbo } from '../../shared/interfaces/gumboInterfaces';
import { getPlay } from '../../shared/utils/gumbo/gumboUtils';
import GumboEventJsonViewer from './GumboEventJsonViewer';
import GumboPlayJsonViewer from './GumboPlayJsonViewer';

interface GumboJsonViewerProps {
  gumbo: Gumbo;
  playIndex: number;
  eventIndex: number;
}

export default function GumboJsonViewer(props: GumboJsonViewerProps) {

  const play = getPlay(props.gumbo, props.playIndex);
  const event = play.playEvents[props.eventIndex];

  return (
    <Tabs
      size='sm'
      width='505px'
      height='850px'
      border='1px solid lightgrey'
      borderRadius='md'
    >
      <TabList>
        <Tab>Play</Tab>
        <Tab>Event</Tab>
      </TabList>
      <TabPanels>
        <TabPanel padding={0}>
          <GumboPlayJsonViewer play={play} />
        </TabPanel>
        <TabPanel padding={0}>
          <GumboEventJsonViewer event={event} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
