import React from 'react';
import { Select } from '@chakra-ui/react';

interface TeamAbbrDropdownProps {
  teams: string[];
  value: string;
  onChange: (value: string) => void;
}

export default function TeamAbbrDropdown(props: TeamAbbrDropdownProps) {

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(event.target.value);
  }

  // TODO: display full team name.
  return (
    <Select placeholder='Select team' value={props.value} onChange={handleChange}>
      {props.teams.map((team) => {
        return <option key={team} value={team}>{team}</option>;
      })}
    </Select>
  );
}
