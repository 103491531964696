import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  Stack,
} from '@chakra-ui/react';
import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import firebaseApp from '../../firebase';

const auth = getAuth(firebaseApp);

interface LogInModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LogInModal({ isOpen, onClose }: LogInModalProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [
    signInWithEmailAndPassword,
    userLogin,
    loadingLogin,
    errorLogin,
  ] = useSignInWithEmailAndPassword(auth);

  const [user, loading, error] = useAuthState(auth);

  const initialRef = useRef(null);

  useEffect(() => {
    if (user != null) {
      onClose();
    }
  }, [user, onClose]);

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Log In</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl id='email'>
              <FormLabel>Email address</FormLabel>
              <Input
                ref={initialRef}
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id='password'>
              <FormLabel>Password</FormLabel>
              <Input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            {errorLogin &&
            <Alert status='error' rounded={'md'}>
              <AlertIcon />
              <AlertDescription>Invalid email or password.</AlertDescription>
            </Alert>
            }
          </Stack>
        </ModalBody>
        {/* TODO: add forgot password link */}

        <ModalFooter>
          <Button
            colorScheme='blue'
            mr={3}
            onClick={() => signInWithEmailAndPassword(email, password)}
          >
            Log In
            {/* TODO: add spinner if login is loading */}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
