import * as Plotly from 'plotly.js';
import loess from '@stdlib/stats-lowess';
import { kZone } from '../constants/statcast';

export function genLoessTrace(
  x: number[],
  y: number[],
  color?: string,
  smootherSpan?: number
): Plotly.Data {
  const options = smootherSpan == null ? undefined : { f: smootherSpan };
  const out = loess(x, y, options);
  const marker = color == null ? undefined : { color };
  const trace = {
    ...out,
    mode: 'lines',
    marker,
  } as Plotly.Data;
  return trace;
}

export function genStrikeZoneShape(color: string, width: number): Partial<Plotly.Shape> {
  return {
    ...kZone,
    type: 'rect',
    line: { color, width },
  } as Partial<Plotly.Shape>;
}

export const blackStrikeZone = genStrikeZoneShape('#000000', 2);
export const whiteStrikeZone = genStrikeZoneShape('#ffffff', 2);

export function genStrikeZoneShapesBlack(n: number): Partial<Plotly.Shape>[] {
  return genStrikeZoneShapes('#000000', 2, n);
}

export function genStrikeZoneShapes(color: string, width: number, n: number): Partial<Plotly.Shape>[] {
  let shapes: Partial<Plotly.Shape>[] = [];
  for (let i = 1; i <= n; i++) {
    const shape: Partial<Plotly.Shape> = {
      ...genStrikeZoneShape(color, width),
      xref: `x${i}` as Plotly.XAxisName,
      yref: `y${i}` as Plotly.AxisName,
    } as Partial<Plotly.Shape>;
    shapes.push(shape);
  }
  return shapes;
}

export function genPlateScatterBorder(color: string, width: number, margin: number = 0.02): Partial<Plotly.Shape>[] {
  const leftCoords = {
    x0: -2 + margin,
    y0: 0,
    x1: -2 + margin,
    y1: 5 - margin
  };
  const topCoords = {
    x0: -2 + margin,
    y0: 5 - margin,
    x1: 2 - margin,
    y1: 5 - margin,
  };
  const rightCoords = {
    x0: 2 - margin,
    y0: 0,
    x1: 2 - margin,
    y1: 5 - margin,
  };
  return [leftCoords, topCoords, rightCoords].map(coords => {
    return {
      ...coords,
      type: 'line',
      line: { color, width },
    } as Partial<Plotly.Shape>;
  });
}

export function genPlateScatterBorderRect(color: string, width: number): Partial<Plotly.Shape> {
  const coords = {
    x0: -2,
    y0: 0,
    x1: 2,
    y1: 5,
  };
  return {
    ...coords,
    type: 'rect',
    line: { color, width },
  } as Partial<Plotly.Shape>;
}
