import PitchMix from '../../plotly/PitchMix';
import { StatcastRawPitcherData } from '../../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../../shared/interfaces/gameInterfaces';
import { filterPitcherDataByExactCount } from '../../../../shared/utils/filterUtils';

interface LivePitchMixProps {
  pitcherData: StatcastRawPitcherData;
  countFilter?: Count;
}

export default function LivePitchMix(props: LivePitchMixProps) {

  const filtered = filterPitcherDataByExactCount(props.pitcherData, props.countFilter);
  const pitchTypes = filtered.map(x => x.pitch_type);

  return (
    <PitchMix pitch_type={pitchTypes} />
  );
}
