import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { StatcastRawPitcherData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import PitchLocation from '../wrappers/PitchLocation';

interface PitchLocationContainerProps {
  data: StatcastRawPitcherData;
  batterPov: boolean;
  countFilter?: Count;
}

function PitchLocationContainer(props: PitchLocationContainerProps) {

  return (
    <VStack
      justify='space-between'
      border='1px solid lightgrey'
      borderRadius='md'
      width='704px'
      height='465px'
    >
      <Box>
        Pitch Location
      </Box>
      <Box height='400px' width='700px'>
        <PitchLocation
          batterPov={props.batterPov}
          countFilter={props.countFilter}
          data={props.data}
        />
      </Box>
    </VStack>
  );
}

export default React.memo(PitchLocationContainer);
