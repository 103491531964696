import { Box, HStack } from '@chakra-ui/react'
import { StatcastRawBatterData, StatcastRawPitcherData, StatsApiLinescoreResponse } from '../../shared/interfaces/apiInterfaces';
import { determineBatterStance, determineCommonPitches, determinePitcherThrows } from '../../shared/utils/calcUtils';
import { filterVsBatterHandedness, filterVsPitcherHandedness } from '../../shared/utils/filterUtils';
import { getAdvancedCount } from '../../shared/utils/gameUtils';
import BatterSwingScatterContainer from '../charts/containers/BatterSwingScatterContainer';
import SprayChartContainer from '../charts/containers/SprayChartContainer';
import PitchMovementContainer from '../charts/containers/PitchMovementContainer';
import PitchLocationContainer from '../charts/containers/PitchLocationContainer';
import LiveInfoAndToggles from './containers/LiveInfoAndToggles';
import PitchLegendMix from './containers/PitchLegendMix';

interface LiveGameDataProps {
  linescoreResponse: StatsApiLinescoreResponse;
  pitcherData: StatcastRawPitcherData;
  batterData: StatcastRawBatterData;
  delay: number;
  setDelay: (value: number) => void;
  batterPov: boolean;
  setBatterPov: (value: boolean) => void;
  handednessFilter: boolean;
  setHandednessFilter: (value: boolean) => void;
  countFilter: boolean;
  setCountFilter: (value: boolean) => void;
}

export default function LiveGameData(props: LiveGameDataProps) {

  const throws = determinePitcherThrows(props.pitcherData.map(x => x.p_throws));
  const stands = determineBatterStance(props.batterData.map(x => x.stand));

  const pitchTypesToColor = determineCommonPitches(props.pitcherData.map(x => x.pitch_type));

  const batterHandedness = props.handednessFilter ? stands : null;
  const pitcherHandedness = props.handednessFilter ? throws : null;

  const filteredPitcherData = filterVsBatterHandedness(props.pitcherData, batterHandedness);
  const filteredBatterData = filterVsPitcherHandedness(props.batterData, pitcherHandedness);

  const count = getAdvancedCount(props.linescoreResponse.linescore);
  const countFilter = props.countFilter ? count : undefined;

  return (
    <Box>
      <HStack>
        <LiveInfoAndToggles
          linescore={props.linescoreResponse.linescore}
          delay={props.delay}
          setDelay={props.setDelay}
          handednessFilter={props.handednessFilter}
          setHandednessFilter={props.setHandednessFilter}
          countFilter={props.countFilter}
          setCountFilter={props.setCountFilter}
          batterPov={props.batterPov}
          setBatterPov={props.setBatterPov}
        />
        <PitchLegendMix
          countFilter={countFilter}
          pitcherData={filteredPitcherData}
        />
        <PitchMovementContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <SprayChartContainer
          batterData={filteredBatterData}
          pitchTypesToColor={pitchTypesToColor}
          countFilter={countFilter}
        />
      </HStack>
      <HStack>
        <PitchLocationContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={filteredPitcherData || []}
        />
        <BatterSwingScatterContainer
          batterPov={props.batterPov}
          countFilter={countFilter}
          data={(filteredBatterData || [])}
          pitchTypesToColor={pitchTypesToColor}
        />
      </HStack>
    </Box>
  );
}
