import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import PitchMovement from '../plotly/PitchMovement';
import { StatcastRawPitcherData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';

interface PitchMovementContainerProps {
  data: StatcastRawPitcherData;
  batterPov: boolean;
  countFilter?: Count;
}

function PitchMovementContainer(props: PitchMovementContainerProps) {

  return (
    <VStack
      justify='space-between'
      border='1px solid lightgrey'
      borderRadius='md'
      width='404px'
    >
      <Box>
        Pitch Movement
      </Box>
      <Box height='350px' width='350px'>
        <PitchMovement
          batterPov={props.batterPov}
          data={props.data}
          countFilter={props.countFilter}
        />
      </Box>
    </VStack>
  );
}

export default React.memo(PitchMovementContainer);
