import { Link as ReactLink } from 'react-router-dom';
import { As, Box, Center, Flex, Spacer, StackDivider, VStack } from '@chakra-ui/react';
import { ExtendedStatsApiSchedule } from '../../../shared/interfaces/apiInterfaces';
import { formatInning, formatTime } from '../../../shared/utils/formatUtils';
import { shouldScoreBeDisplayed } from '../../../shared/utils/gameUtils';

interface GameScoreboardProps {
  game: ExtendedStatsApiSchedule;
}

export default function GameScoreboard(props: GameScoreboardProps) {

  const shouldDisplayScore = shouldScoreBeDisplayed(props.game);
  const isClickable = props.game.parsed_game_state === 'Live';
  const href = `/live/game/${props.game.game_pk}`;

  const clickableProps = isClickable ? {
    as: ReactLink as As<any>,
    to: href
  } : {};

  return (
    <Flex w='300px'
      border='1px solid' borderRadius='md' borderColor='gray.500'
      {...clickableProps}
    >
      <Box w='200px' borderRight='1px solid' borderColor='gray.200'>
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          align='stretch'
          spacing={0}
        >
          <Box>
            <Flex margin='0.35rem'>
              <Center>{props.game.away_team_name}</Center>
              <Spacer />
              {shouldDisplayScore && <Box>{props.game.away_runs}</Box>}
            </Flex>
          </Box>
          <Box>
            <Flex margin='0.35rem'>
              <Center>{props.game.home_team_name}</Center>
              <Spacer />
              {shouldDisplayScore && <Box>{props.game.home_runs}</Box>}
            </Flex>
          </Box>
        </VStack>
      </Box>
      <Spacer />
      <Box w='100px'>
        <GameState game={props.game} />
      </Box>
    </Flex>
  );
}

function GameState({ game }: { game: ExtendedStatsApiSchedule }) {
  switch (game.parsed_game_state) {
    case 'Preview':
      return <Center h='100%' w='100%'>{formatTime(game.game_date)}</Center>;
    case 'Live':
      return <Center h='100%' w='100%'>{formatInning(game)}</Center>;
    case 'DelayedLive':
      return (
        <VStack h='100%' w='100%'>
          <Center w='100%'>{formatInning(game)}</Center>
          <Center w='100%'>Delayed</Center>
        </VStack>
      );
    case 'DelayedPreview': case 'Delayed':
      return <Center h='100%' w='100%'>Delayed</Center>;
    case 'Postponed':
      return <Center h='100%' w='100%'>Postponed</Center>;
    case 'Cancelled':
      return <Center h='100%' w='100%'>Cancelled</Center>;
    case 'Final':
      return <Center h='100%' w='100%'>Final</Center>;
    default:
      return <Box></Box>;
  }
}
