import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { PlayerStatsStore } from '../../shared/classes/PlayerStatsStore';
import { DelayedLinescore, DelayedPlay } from '../../shared/interfaces/appGumboInterfaces';
import { Gumbo } from '../../shared/interfaces/gumboInterfaces';
import { GumboUpdate } from '../../shared/interfaces/apiInterfaces';
import { getDelayedLinescore, getDelayedPlayLocation } from '../../shared/utils/gumbo/gumboTraversalUtils';
import LiveGameDashboard from './LiveGameDashboard';

const updateDisplayInterval = 250;
const defaultDelay = 25;

interface LiveGamePreferencesProps {
  gumbo: Gumbo;
  updates: GumboUpdate[];
  preparedData: PlayerStatsStore;
}

export default function LiveGamePreferences(props: LiveGamePreferencesProps) {

  const [delay, setDelay] = useState(defaultDelay);
  const [batterPov, setBatterPov] = useState(true);
  const [handednessFilter, setHandednessFilter] = useState(false);
  const [countFilter, setCountFilter] = useState(false);

  const [displayLinescore, setDisplayLinescore] = useState<DelayedLinescore | null>(null);
  const [displayPlay, setDisplayPlay] = useState<DelayedPlay | null>(null);

  function updateDisplayData(gumbo: Gumbo, updates: GumboUpdate[], delay: number) {
    const newPlay = getDelayedPlayLocation(gumbo, delay);
    setDisplayPlay(newPlay);
    const newLinescore = getDelayedLinescore(updates, delay);
    setDisplayLinescore(newLinescore || null);
  }

  useEffect(() => {
    // TODO: make we are using intervals correctly here.
    updateDisplayData(props.gumbo, props.updates, delay);
    const interval = setInterval(() => updateDisplayData(props.gumbo, props.updates, delay), updateDisplayInterval);
    return () => clearInterval(interval);
  }, [props.gumbo, props.updates, delay]);


  if (displayPlay == null) {
    return (
      <Box>
        Loading current play...
      </Box>
    );
  }

  if (displayLinescore == null) {
    return (
      <Box>
        Loading current linescore...
      </Box>
    );
  }

  // if (displayPlay == null || displayLinescore == null) {
  //   // TODO: figure out what to display here.
  //   // The user may have selected a delay that is too long, preventing us from getting an old enough linescore.
  //   return (
  //     <Box>
  //       Loading...
  //     </Box>
  //   );
  // }

  return (
    <Box>
      <LiveGameDashboard
        gumbo={props.gumbo}
        updates={props.updates}
        displayPlay={displayPlay}
        displayLinescore={displayLinescore}
        preparedData={props.preparedData}
        delay={delay}
        setDelay={setDelay}
        batterPov={batterPov}
        setBatterPov={setBatterPov}
        handednessFilter={handednessFilter}
        setHandednessFilter={setHandednessFilter}
        countFilter={countFilter}
        setCountFilter={setCountFilter}
      />
    </Box>
  );
}
