import { pitchColors } from '../constants/statcast';

export function generateStaticSpectrumColorTheme(color: string) {
  return {
    50: color,
    100: color,
    200: color,
    300: color,
    400: color,
    500: color,
    600: color,
    700: color,
    800: color,
    900: color,
  };
}

export function generateStaticThemeColors(colors: [string, string][]) {
  return Object.fromEntries(colors.map(c => [c[0], generateStaticSpectrumColorTheme(c[1])]));
}

export const pitchColorTheme = generateStaticThemeColors(Object.entries(pitchColors));
