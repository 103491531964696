import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParam, NumberParam } from 'use-query-params';
import { Box } from '@chakra-ui/react';
import { getGumbo } from '../api/backendApi';
import { Gumbo } from '../shared/interfaces/gumboInterfaces';
import GameReviewComponent from '../components/review/GameReviewComponent';

export default function GameReview() {

  const gamePk = parseInt(useParams().gamePk || '');

  const [playIndex, setPlayIndex] = useQueryParam('play', NumberParam);
  const [eventIndex, setEventIndex] = useQueryParam('event', NumberParam);

  const [gumbo, setGumbo] = useState<Gumbo | null>(null);

  const updatePlayIndex = (idx: number) => {
    setPlayIndex(idx);
    setEventIndex(0);
  }

  useEffect(() => {
    if (gamePk != null) {
      getGumbo(gamePk).then(setGumbo);
    }
  }, [gamePk]);

  if (gumbo == null) {
    return <Box>Loading...</Box>
  }

  return (
    <GameReviewComponent
      gumbo={gumbo}
      playIndex={playIndex || 0}
      setPlayIndex={updatePlayIndex}
      eventIndex={eventIndex || 0}
      setEventIndex={setEventIndex}
    />
  );
}
