import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react'

interface NumberRadioProps {
  label: string;
  values: number[];
  value: number;
  setValue: (value: number) => void;
}

export default function NumberRadio(props: NumberRadioProps) {

  function setValue(value: string) {
    props.setValue(parseInt(value));
  }

  return (
    <RadioGroup onChange={setValue} value={props.value}>
      <Stack direction='row'>
        <Box>{props.label}</Box>
        {props.values.map((value) => {
          return <Radio key={value} value={value}>{value}</Radio>;
        })}
      </Stack>
    </RadioGroup>
  );
}
