import BooleanCheckbox from './base/BooleanCheckbox';

interface HandednessToggleProps {
  handednessFilter: boolean;
  setHandednessFilter: (value: boolean) => void;
}

export default function HandednessToggle(props: HandednessToggleProps) {

  return (
    <BooleanCheckbox
      value={props.handednessFilter}
      setValue={props.setHandednessFilter}
      text='Handedness Filter'
    />
  );
}
