import React from 'react';
import { Select } from '@chakra-ui/react';

interface SeasonDropdownProps {
  seasons: number[];
  value: number;
  onChange: (value: number) => void;
}

export default function SeasonDropdown(props: SeasonDropdownProps) {

  function handleSeasonChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(Number(event.target.value));
  }

  return (
    <Select placeholder='Select season' value={props.value} onChange={handleSeasonChange}>
      {props.seasons.map((season) => {
        return <option key={season} value={season}>{season}</option>;
      })}
    </Select>
  );
}
