import { HStack, VStack } from '@chakra-ui/react';
import { pitchToColor } from '../../../shared/constants/statcast';
import { zeroMargins } from '../../../shared/constants/plotly';
import { determineCommonPitches } from '../../../shared/utils/calcUtils';
import { StatcastRawPitcherData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import { filterStatcastByExactCount, orderPitches } from '../../../shared/utils/filterUtils';
import PlateScatter, { PlateScatterProps } from '../plotly/PlateScatter';

interface PitchLocationProps {
  data: StatcastRawPitcherData;
  batterPov: boolean;
  countFilter?: Count;
}

export default function PitchLocation(props: PitchLocationProps) {

  const filtered = props.countFilter != null ? filterStatcastByExactCount(props.data, props.countFilter.balls, props.countFilter.strikes) : props.data;

  const pitch_type = filtered.map(x => x.pitch_type);
  const plate_x = filtered.map(x => x.plate_x);
  const plate_z = filtered.map(x => x.plate_z);

  const pitches = determineCommonPitches(pitch_type);
  const orderedPitches = orderPitches(pitches);
  const numPitches = pitches.length;

  let pitchStats: { [key: string]: [number, number][] } = {};
  for (const pitch of pitches) {
    pitchStats[pitch] = [];
  }
  for (let i = 0; i < filtered.length; i++) {
    for (const pitch of pitches) {
      if (pitch === pitch_type[i]) {
        pitchStats[pitch].push([plate_x[i], plate_z[i]]);
      }
    }
  }

  let rows = 1;
  let columns = numPitches;
  if (numPitches > 3) {
    rows = 2;
    columns = numPitches - Math.floor(numPitches / 2);
  }

  let row1: PlateScatterProps[] = [];
  let row2: PlateScatterProps[] = [];

  for (let i = 0; i < orderedPitches.length; i++) {
    const pitch = orderedPitches[i];
    const x = pitchStats[pitch].map(x => x[0]);
    const y = pitchStats[pitch].map(x => x[1]);
    const pitchColor = pitchToColor(pitch);
    const color = Array(x.length).fill(pitchColor);
    const data = { x, y, color, batterPov: props.batterPov };

    if (rows === 1) {
      row1.push(data);
    } else if (numPitches === 4) {
      if (i < 2) {
        row1.push(data);
      } else {
        row2.push(data);
      }
    } else {
      if (i < 3) {
        row1.push(data);
      } else {
        row2.push(data);
      }
    }
  }

  const margin = {
    ...zeroMargins,
    b: 10
  };

  // TODO: figure out a different solution besides generating random keys?

  if (rows === 1) {
    return (
      <HStack width='696px' height='390px'>
        {row1.map(data => {
          return (
            <PlateScatter
              key={Math.random()}
              x={data.x}
              y={data.y}
              color={data.color}
              batterPov={props.batterPov}
              pitchColorBorder={true}
              margin={margin}
            />
          )
        })}
      </HStack>
    )
  } else {
    return (
      <VStack>
        <HStack width='696px' height='195px'>
          {row1.map(data => {
            return (
              <PlateScatter
                key={Math.random()}
                x={data.x}
                y={data.y}
                color={data.color}
                batterPov={props.batterPov}
                pitchColorBorder={true}
                margin={margin}
              />
            )
          })}
        </HStack>
        <HStack width='696px' height='195px'>
          {row2.map(data => {
            return (
              <PlateScatter
                key={Math.random()}
                x={data.x}
                y={data.y}
                color={data.color}
                batterPov={props.batterPov}
                pitchColorBorder={true}
                margin={margin}
              />
            )
          })}
        </HStack>
      </VStack>
    );
  }
}
