import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import { dontDrawAxisOptions, zeroMargins } from '../../../shared/constants/plotly';
import { pitchToColor } from '../../../shared/constants/statcast';
import { StatcastRawBatterData } from '../../../shared/interfaces/apiInterfaces';
import { Count } from '../../../shared/interfaces/gameInterfaces';
import { filterBatterDataByExactCount } from '../../../shared/utils/filterUtils';

interface SprayChartProps {
  batterData: StatcastRawBatterData;
  countFilter?: Count;
  pitchTypesToDisplay?: string[];
  pitchTypesToColor?: string[];
}

export default function SprayChart(props: SprayChartProps) {

  const filtered = filterData(props);
  const toColor = props.pitchTypesToColor == null ? [] : props.pitchTypesToColor;

  const x = filtered.map(x => x.hc_x);
  const y = filtered.map(x => x.hc_y * -1);
  const color = filtered.map(x => toColor.includes(x.pitch_type) ? pitchToColor(x.pitch_type) : 'darkgrey');

  const foulLineLeft = {
    ...foulLineLeftCoords,
    type: 'line',
    line: { color: '#000000', width: 2 }
  };
  const foulLineRight = {
    ...foulLineRightCoords,
    type: 'line',
    line: { color: '#000000', width: 2 }
  };
  const grassLine = {
    path: grassLinePath,
    type: 'path',
    line: { color: '#000000', width: 2, dash: 'dot' },
  };
  const shapes = [foulLineLeft, foulLineRight, grassLine] as Partial<Plotly.Shape>[];

  const yaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    range: [-228, 15],
    constrain: 'domain',
    scaleanchor: 'x',
    scaleratio: 1,
  } as Partial<Plotly.LayoutAxis>;

  const xaxis: Partial<Plotly.LayoutAxis> = {
    ...dontDrawAxisOptions,
    range: [13, 253],
    constrain: 'domain',
  } as Partial<Plotly.LayoutAxis>;

  return (
    <Plot
      data={[
        {
          x,
          y,
          type: 'scattergl',
          mode: 'markers',
          marker: { color },
        },
      ]}
      layout={{
        autosize: true,
        shapes,
        yaxis,
        xaxis,
        margin: zeroMargins,
      }}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      config={{ staticPlot: true }}
    />
  );
}

function filterData(props: SprayChartProps) {
  const byCount = filterBatterDataByExactCount(props.batterData, props.countFilter);
  const contactOnly = byCount.filter(x => x.type === 'X');

  if (props.pitchTypesToDisplay == null) {
    return contactOnly;
  }

  const toDisplay = (props.pitchTypesToDisplay || []).filter(x => x !== 'other');
  const colored = contactOnly.filter(x => toDisplay.includes(x.pitch_type));

  if (props.pitchTypesToDisplay.includes('other')) {
    const others = contactOnly.filter(x => !(props.pitchTypesToColor || []).includes(x.pitch_type));
    return others.concat(colored);
  }

  return colored;
}

const foulLineLeftCoords = {
  x0: 128,
  y0: -208,
  x1: 33,
  y1: -100,
};

const foulLineRightCoords = {
  x0: 128,
  y0: -208,
  x1: 233,
  y1: -100,
};

const grassLinePath = 'M 83,-155 C 83,-115 173,-115 173,-155';
