import { Link as ReactLink } from 'react-router-dom';
import { Box, Center, Flex, HStack, Spacer, StackDivider } from '@chakra-ui/react';
import { teamMap } from '../../shared/constants/mlb';
import { GameScore } from '../../shared/interfaces/apiInterfaces';
import { formatUtcDate } from '../../shared/utils/formatUtils';

interface GameScoreReviewLinkProps {
  game: GameScore;
}

export default function GameScoreReviewLink({ game }: GameScoreReviewLinkProps) {

  const href = `/gamereview/game/${game.gamepk}`;

  const homeWinner = game.homeruns > game.awayruns;
  const awayFontWeight = homeWinner ? 'normal' : 'bold';
  const homeFontWeight = homeWinner ? 'bold' : 'normal';

  const dateString = formatUtcDate(new Date(game.gamedate));

  return (
    <Flex
      as={ReactLink}
      to={href}
      w='400px'
      border='1px solid' borderRadius='md' borderColor='gray.500'
    >
      <Box w='300px' borderRight='1px solid' borderColor='gray.200'>
        <HStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={0}
        >
          <Box w='50%'>
            <Flex margin='0.35rem' fontWeight={awayFontWeight}>
              <Center>{teamMap.get(game.awayteamid)?.shortName}</Center>
              <Spacer />
              <Box>{game.awayruns}</Box>
            </Flex>
          </Box>
          <Box w='50%'>
            <Flex margin='0.35rem' fontWeight={homeFontWeight}>
              <Center>{teamMap.get(game.hometeamid)?.shortName}</Center>
              <Spacer />
              <Box>{game.homeruns}</Box>
            </Flex>
          </Box>
        </HStack>
      </Box>
      <Spacer />
      <Box w='100px'>
        <Center margin='0.35rem'>{dateString}</Center>
      </Box>
    </Flex>
  );
}
