import { ReactNode } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Stack,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import firebaseApp from '../../firebase';

import LogIn from '../auth/LogIn';
import LogOut from '../auth/LogOut';

const auth = getAuth(firebaseApp);

interface NavLinkProps {
  children: ReactNode,
  href: string
};

const NavLink = ({ children, href }: NavLinkProps) => (
  <Link
    as={ReactLink}
    to={href}
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}>
    {children}
  </Link>
);

export default function NavBar({ links }: { links: NavLinkProps[] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [user, loading, error] = useAuthState(auth);

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <HStack spacing={8} alignItems={'center'}>
          <Box>The Fifth Seam</Box>
          <HStack
            as={'nav'}
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
          >
            {links.map((link) => (
              <NavLink key={link.href} href={link.href}>{link.children}</NavLink>
            ))}
          </HStack>
        </HStack>
        <Box>
          <HStack
            as={'nav'}
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
          >
            {loading ? null : (user == null ? (
              <>
                <Box>Sign Up</Box>
                <LogIn />
              </>
            ): <LogOut />)
            }
          </HStack>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
        </Box>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {links.map((link) => (
              <NavLink key={link.href} href={link.href}>{link.children}</NavLink>
            ))}
            {loading ? null : (user == null ? (
              <>
                <Box>Sign Up</Box>
                <LogIn />
              </>
            ): <LogOut />)
            }
          </Stack>
        </Box>
      ) : null}
  </Box>
  );
}
