import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface SidebarDropdownProps {
  children: ReactNode;
}

export default function SidebarDropdown(props: SidebarDropdownProps) {

  return (
    <Box width={{ base: '200px', sm: 'auto' }}>
      {props.children}
    </Box>
  );
}
