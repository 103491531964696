import { Heading, Text, VStack } from '@chakra-ui/react';

export default function SiteDescription() {

  const text1 = (
    <Text>
      The Fifth Seam is an interactive baseball website that provides insight, information, and context to the fan before, during, and after the game as well as throughout the whole season.  Game previews display lineup and pitching info as well as key match ups that highlight both opportunities and threats each game.  Real-time, customizable dashboards provide pitch by pitch information on the game in progress and insights into pitcher and batter tendencies as the game situation and count changes.  Game recaps and graphically annotated highlights show exactly what happened and why in each game in easily digestible form.  Our season recaps also show player contributions and team composition in a new light.
    </Text>
  );

  const text2 = (
    <Text>
      Whether you are looking for a preview of who your team is taking on next series, real-time pitch mix and plate discipline stats, or a new way to interact with highlights, The Fifth Seam truly brings “a better grip on baseball data.”
    </Text>
  );

  return (
    <VStack>
      {text1}
      {text2}
    </VStack>
  );
}
