import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { StatcastRawBatterData, StatcastRawPitcherData, StatsApiLinescoreResponse, StatsApiLinescoresResponse } from '../shared/interfaces/apiInterfaces';
import { getBatterDataRecords, getPitcherDataRecords } from '../api/statcastApi';
import { getStatsApiLinescores } from '../api/liveApi';
import LiveGameData from '../components/live/LiveGameData';

const queryLinescoresInterval = 2 * 1000;
const updateLinescoreInterval = 250;
const defaultDelay = 25;

export default function LiveGameOld() {

  const gamePk = parseInt(useParams().gamePk || '');

  // We need to load data for every player in the lineup and both pitchers immmediately upon a user visiting a live game page.
  // This should all be cached on the server (all the player data).

  // After an inning is completed, the pitcher and batter change before the inning/outs/balls/strikes do.

  const [delay, setDelay] = useState(defaultDelay);
  const [batterPov, setBatterPov] = useState(true);
  const [handednessFilter, setHandednessFilter] = useState(false);
  const [countFilter, setCountFilter] = useState(false);

  const [displayLinescore, setDisplayLinescore] = useState<StatsApiLinescoreResponse | null>(null);

  const [linescoresResponse, setLinescoresResponse] = useState<StatsApiLinescoresResponse | null>(null);

  const [pitcherData, setPitcherData] = useState<StatcastRawPitcherData | null>(null);
  const [batterData, setBatterData] = useState<StatcastRawBatterData | null>(null);

  const pitcher = displayLinescore?.linescore?.pitcher_id;
  const batter = displayLinescore?.linescore?.batter_id;
  const gameState = displayLinescore?.game_state;

  function updateLinescores(gamePk: number) {
    getStatsApiLinescores(gamePk).then(setLinescoresResponse);
  }

  function updateLinescore(linescoresResponse: StatsApiLinescoresResponse | null, delay: number) {
    if (linescoresResponse == null) {
      setDisplayLinescore(null);
    } else {
      const cutoff = new Date();
      cutoff.setSeconds(cutoff.getSeconds() - delay);

      const linescores = linescoresResponse?.linescores || [];
      for (const linescore of linescores) {
        const d = new Date(linescore.received_at);
        if (d <= cutoff) {
          setDisplayLinescore({
            linescore,
            game_state: linescoresResponse.game_state
          });
          return;
        }
      }
    }
  }

  useEffect(() => {
    // TODO: make we are using intervals correctly here.
    updateLinescore(linescoresResponse, delay);
    const interval = setInterval(() => updateLinescore(linescoresResponse, delay), updateLinescoreInterval);
    return () => clearInterval(interval);
  }, [linescoresResponse, delay]);

  useEffect(() => {
    updateLinescores(gamePk);
    const interval = setInterval(() => updateLinescores(gamePk), queryLinescoresInterval);
    return () => clearInterval(interval);
  }, [gamePk]);

  useEffect(() => {
    if (pitcher != null) {
      getPitcherDataRecords(pitcher).then(setPitcherData);
    }
  }, [pitcher]);

  useEffect(() => {
    if (batter != null) {
      getBatterDataRecords(batter).then(setBatterData);
    }
  }, [batter]);


  if (gameState !== 'Live') {
    return (
      <Box>
        <Box>Game is not live</Box>
      </Box>
    );
  }

  return (
    <Box>
      {displayLinescore != null &&
      <Box>
        <LiveGameData
          delay={delay}
          setDelay={setDelay}
          batterPov={batterPov}
          setBatterPov={setBatterPov}
          handednessFilter={handednessFilter}
          setHandednessFilter={setHandednessFilter}
          countFilter={countFilter}
          setCountFilter={setCountFilter}
          linescoreResponse={displayLinescore}
          pitcherData={pitcherData || []}
          batterData={batterData || []}
        />
      </Box>
      }
    </Box>
  );
}
