import React from 'react';
import { Select } from '@chakra-ui/react';
import { teamList } from '../../shared/constants/mlb';

interface TeamDropdownProps {
  value: number | null;
  onChange: (value: number) => void;
}

export default function TeamDropdown(props: TeamDropdownProps) {

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(Number(event.target.value));
  }

  const value = props.value == null ? '' : props.value;

  return (
    <Select
      placeholder='Select team'
      value={value}
      onChange={handleChange}
    >
      {teamList.map((team) => {
        const name = team.name;
        return <option key={team.id} value={team.id}>{name}</option>;
      })}
    </Select>
  );
}
