import {
  Link,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import LogInModal from './LogInModal';

export default function LogIn() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Link
        onClick={onOpen}
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: useColorModeValue('gray.200', 'gray.700'),
        }}>
        Log In
      </Link>
      {isOpen && <LogInModal isOpen={isOpen} onClose={onClose}></LogInModal>}
    </>
  );
}
